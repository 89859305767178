import { useReducer, useEffect, createContext, PropsWithChildren } from 'react';
import { Constants } from '../utils/constants';
import { initializeState, updateContextState } from '../utils/helpers';
import { updateS3 } from '../utils/apiCalls/awsS3';
import {BaseActionType} from '../utils/types/util_types';
import {RouteProps} from 'react-router-dom';

export type DashboardStateObject = {
  sidebarState: boolean,
  updateState: boolean
}

const initialState = {
  sidebarState: false,
  updateState: false
};

const DashboardReducer = (state: DashboardStateObject, action: BaseActionType) => {
  return { ...state, sidebarState: false, ...action.payload };
};

const actions = (dispatch: (_: any) => void, state: DashboardStateObject) => {
  return ({
    toggleSidebar: () => {
      const { sidebarState } = state;
      dispatch({
        type: Constants.Actions.Dashboard.ToggleSidebar,
        payload: { sidebarState: !sidebarState }
      });
    },

    s3Upload: async (url: string, file: File) => {
      return updateS3(url, file);
    },
  });
};

type DashboardContextActions = ReturnType<typeof actions>
type DashboardContextType = {state: DashboardStateObject} & DashboardContextActions
export const DashboardContext = createContext<DashboardContextType>({} as DashboardContextType);

export const DashboardProvider = (
  { children, value }: PropsWithChildren<{value?: DashboardStateObject}> & { routerProps?: RouteProps }
) => {
  const [state, dispatch] = useReducer(
    DashboardReducer,
    initializeState(Constants.StorageKey.Dashboard, { ...initialState, ...(value as DashboardStateObject ?? {})})
  );

  useEffect(() => {
    if (state.updateState)
      updateContextState(Constants.StorageKey.Dashboard, state);
  }, [state]);

  return (
    <DashboardContext.Provider value={{ state, ...actions(dispatch, state) }}>
      {children}
    </DashboardContext.Provider>
  );
};
