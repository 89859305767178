import { useContext, useEffect, useState } from 'react';
import CModal from '../../../../shared/forms/Modal';
import CButton from '../../../../shared/forms/Button';
import { ServiceType } from '../../../../utils/types/service_types';
import { Constants } from '../../../../utils/constants';
import { CDashboardTable } from '../../../../shared/CDashboardTable';
import { paginateArray } from '../../../../utils/helpers';
import { CTableData, CTableRow } from '../../../../shared/CTable';
import { BusinessContext } from '../../business-profile/useBusinessContext';

const AssignedServices = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<ServiceType[]>([]);
  const [showAssignedServices, setShowAssignedServices] = useState(false);

  const { getBusiness } = useContext(BusinessContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const business = await getBusiness();

      setLoading(false);

      if (!business) return;

      const serviceAssignments = business.attributes.service_assignments;

      setValues(serviceAssignments);
      await fetchData(1, serviceAssignments);
    })();
  }, []);

  const tableConfig = {
    totalCount: values.length,
    pageRange: Constants.PerPageCount.AssignedServices,
    onPageChange: async (page: number) => {
      if (values.length > 0) await fetchData(page, values);
    },
  };

  const onClose = () => {
    setShowAssignedServices(false);
  };

  const fetchData = async (pageNumber = 1, data = [] as ServiceType[]) => {
    paginateArray(
      Constants.PerPageCount.AssignedServices,
      setValues,
      pageNumber,
      data
    );
  };

  return (
    <div>
      {values.length > 0 && (
        <a className="link-text" onClick={() => setShowAssignedServices(true)}>
          View assigned services
        </a>
      )}

      <CModal
        show={showAssignedServices && !loading}
        onClose={onClose}
        title={`Assigned services - ${values.length}`}
        footer={
          <CButton onClick={onClose} small>
            Ok
          </CButton>
        }
      >
        <CDashboardTable {...tableConfig}>
          {values.map(({ attributes }, index) => (
            <CTableRow key={index}>
              <CTableData classes="only">{attributes.name}</CTableData>
            </CTableRow>
          ))}
        </CDashboardTable>
      </CModal>
    </div>
  );
};

export default AssignedServices;
