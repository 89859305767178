import { FC, useContext } from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {errorToast, isSubscriptionEnabled} from '../utils/helpers';
import { Constants } from '../utils/constants';
import { AuthContext } from '../contexts/useAuthContext';

interface IProps extends RouteProps {
  component: any;
}

export const AuthRoute: FC<IProps> = ({ component: Component, ...rest }) => {
  const { state: authState } = useContext(AuthContext);

  const authComponent = (props: RouteProps) => {
    return <Component {...props} />;
  };

  const setRoute = (props: RouteProps) => {
    if (authState.user) {
      if (!isSubscriptionEnabled) return authComponent(props);

      if (authState.user.subscription) {
        return (
          <>
            {window.location.pathname !== Constants.Links.Auth.Path.Login &&
          window.location.pathname !== Constants.Links.Auth.Path.SelectPlan &&
            errorToast('You are not authorized to visit this page')}
            <Redirect to={Constants.Links.Sidebar.Path.Home} />
          </>);
      } else {
        return authComponent(props);
      }
    } else {
      return authComponent(props);
    }
  };

  return (
    <Route
      {...rest}
      render={props => setRoute(props)}
    />
  );
};
