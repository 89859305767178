import { Card } from 'react-bootstrap';
import LineChart from '../../utils/charts/line';
import EmptyCard from '../../shared/EmptyCard';

const DashboardBookingCount = ({
  header,
  dateFilterOption = 'Last 7 days',
  data,
  title,
  description
}) => {
  return (
    <div className="dashboard-empty__card w-50">
      <Card className="home-card">
        {
          data.additional_info === '0 bookings' ? (
            <EmptyCard
              title={title}
              header={header}
              description={description}
            />
          ) : (
            <LineChart
              title={header}
              labels={data.labels}
              yAxisData={data.y_axis}
              totalValue={data.additional_info}
              dateFilter={dateFilterOption}
              showBorder={false}
              height="226px"
            />
          )
        }
      </Card>
    </div>
  );
};

export default DashboardBookingCount;
