import { createContext, useReducer, useContext, useEffect, PropsWithChildren } from 'react';
import { Constants } from '../../utils/constants';
import {
  createCategory,
  getCategoryList,
  updateCategory,
  removeCategory
} from '../../utils/apiCalls/category';
import {
  createService,
  getServiceList,
  removeService,
  updateService,
  findService
} from '../../utils/apiCalls/service';
import { AuthContext } from '../../contexts/useAuthContext';
import {
  initializeState,
  updateContextState,
  successToast,
  handleError
} from '../../utils/helpers';
import {FilterItemType} from '../../utils/types/util_types';
import {
  CategoryAttributeType,
  SubCategoryStateObjectType,
  ServiceFullObjectType,
  ServiceStateObjectType,
  ServiceContextActionType,
  ServiceMutationAttribute,
  ServiceStateFullObjectType
} from '../../utils/types/service_types';
import {ApiErrorType} from '../../utils/types/util_types';

const initialState = {
  updateState: false,
  categories: {
    hasCategories: false,
    items: [],
    totalCount: 0,
    pageNumber: 1,
    refetch: false
  },
  services: {
    hasServices: false,
    items: [],
    totalCount: 0,
    pageNumber: 1,
    refetch: false
  }
};

const ServiceReducer = (state: ServiceFullObjectType, action: ServiceContextActionType) => {
  switch (action.type) {
  case Constants.Actions.ServiceData.Service.State:
    return {
      ...state,
      services: action.payload as ServiceStateObjectType
    };
  case Constants.Actions.ServiceData.Category.State:
    return {
      ...state,
      categories: action.payload as SubCategoryStateObjectType
    };
  case Constants.Actions.ServiceData.Service.Refetch:
    return {
      ...state,
      services: { ...state.services, refetch: action.payload } as ServiceStateObjectType
    };
  case Constants.Actions.ServiceData.Category.Refetch:
    return {
      ...state,
      categories: { ...state.categories, refetch: action.payload } as SubCategoryStateObjectType
    };
  case Constants.Actions.ServiceData.ResetUpdateState:
    return {
      ...state,
      updateState: action.payload as boolean
    };
  default:
    return state;
  }
};

const actions = (dispatch: (_: any) => void) => {
  const { state: authState } = useContext(AuthContext);

  const resetUpdateState = (payload: boolean = true) => {
    dispatch({
      type: Constants.Actions.ServiceData.ResetUpdateState,
      payload
    });
  };

  const returnValue = {
    categoryList: async (
      pageNumber: number,
      query: string = '',
      fetchAll: boolean = false
    ) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const categories = await getCategoryList(
          user,
          currentBusinessId,
          pageNumber,
          query,
          fetchAll
        );

        const { data } = categories;

        if (fetchAll) return data.data;

        const payload = {
          hasCategories: data.has_items,
          items: data.data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page,
          refetch: false
        };
        dispatch({
          type: Constants.Actions.ServiceData.Category.State,
          payload
        });
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    serviceList: async (
      pageNumber: number,
      query: string = '',
      filterItems = {} as FilterItemType
    ) => {
      const { user, currentBusinessId } = authState;

      const status = filterItems.status?.toLowerCase() ?? '';

      let subCategoryId;

      if (filterItems.category) {
        const categoryList = await returnValue.categoryList(1, '', true);
        const category = categoryList.find(({ attributes }: {attributes: CategoryAttributeType}) => attributes.name === filterItems.category);
        subCategoryId = category?.id;
      }

      try {
        resetUpdateState();
        const services = await getServiceList(
          user,
          currentBusinessId,
          pageNumber,
          query,
          status,
          subCategoryId || ''
        );

        const { data } = services;

        const payload = {
          hasServices: data.has_items,
          items: data.data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page
        };

        dispatch({
          type: Constants.Actions.ServiceData.Service.State,
          payload
        });
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    addCategory: async (categoryName: string) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const category = await createCategory(
          user,
          currentBusinessId,
          categoryName
        );

        const { data } = category;

        if (data && data.data.attributes) {
          dispatch({
            type: Constants.Actions.ServiceData.Category.Refetch,
            payload: true
          });
        }

        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    addService: async ({
      name,
      description,
      price,
      duration,
      subCategoryId,
      status,
      attendantList = []
    }: ServiceMutationAttribute, referrer?: string) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const service = await createService(user, currentBusinessId, {
          name,
          description,
          price,
          duration,
          subCategoryId,
          status,
          attendantList
        });

        if (referrer) return true;

        const { data } = service;

        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    editCategory: async (id: number, categoryName: string) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const category = await updateCategory(
          user,
          currentBusinessId,
          id,
          categoryName
        );

        const { data } = category;

        if (data && data.data.attributes) {
          dispatch({
            type: Constants.Actions.ServiceData.Category.Refetch,
            payload: true
          });
        }

        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    getService: async (id: string) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const service = await findService(user, currentBusinessId, id);

        const { data } = service;

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    editService: async (
      id: string,
      {
        name,
        description,
        price,
        duration,
        subCategoryId,
        status,
        attendantList = []
      }: ServiceMutationAttribute
    ) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const service = await updateService(id, user, currentBusinessId, {
          name,
          description,
          price,
          duration,
          subCategoryId,
          status,
          attendantList
        });

        const { data } = service;

        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    refetchServices: (payload?: boolean) => {
      dispatch({
        type: Constants.Actions.ServiceData.Service.Refetch,
        payload
      });
    },

    refetchCategories: (payload?: boolean) => {
      dispatch({
        type: Constants.Actions.ServiceData.Category.Refetch,
        payload
      });
    },

    deleteService: async (id: string) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        await removeService(user, currentBusinessId, id);

        returnValue.serviceList(1);
        returnValue.categoryList(1);

        dispatch({
          type: Constants.Actions.ServiceData.Service.Refetch,
          payload: true
        });

        successToast('Successfully deleted service');

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      }
    },

    deleteCategory: async (id: number) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        await removeCategory(user, currentBusinessId, id);

        returnValue.categoryList(1);

        successToast('Successfully deleted category');

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      }
    }
  };
  return returnValue;
};

type ServiceContextActions = ReturnType<typeof actions>
type ServiceContextType = ServiceStateFullObjectType & ServiceContextActions
export const ServiceContext = createContext<ServiceContextType>({} as ServiceContextType);

export default function ServiceProvider({ children, value }: PropsWithChildren<{value?: ServiceFullObjectType}>) {
  const [state, dispatch] = useReducer(
    ServiceReducer,
    initializeState(Constants.StorageKey.Services, { ...initialState, ...(value ?? {})})
  );

  useEffect(() => {
    if (state.updateState)
      updateContextState(Constants.StorageKey.Services, state);
  }, [state]);

  return (
    <ServiceContext.Provider value={{ state, ...actions(dispatch) }}>
      {children}
    </ServiceContext.Provider>
  );
}
