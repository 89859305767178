import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { AuthRoute } from './routes/AuthRoutes';
import { authPages, pages } from './utils/routes';
import { AdminRoute } from './routes/AdminRoutes';
import { PrivateRoute } from './routes/PrivateRoutes';
import NotFound from './shared/NotFound';
import {Constants} from './utils/constants';

const Routes = () => {
  return (
    <Router>
      <div>
        <Switch>
          {authPages.map(({ component, path }) => {
            return (
              <AuthRoute exact path={path} component={component} key={path} />
            );
          })}

          {pages.map(({ component, path, access }) => {
            if (access === Constants.Access.Admin) {
              return (
                < AdminRoute
                  exact
                  path={path}
                  component={component}
                  key={path}
                />
              );
            }

            return (
              <PrivateRoute
                exact
                path={path}
                component={component}
                key={path}
              />
            );
          })}

          <Route path={Constants.Links.Paths.NotFound} component={NotFound} />
          <Redirect to={Constants.Links.Paths.NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default Routes;
