import {Fragment, useContext, useEffect, useState} from 'react';
import { AuthContext } from '../../contexts/useAuthContext';
import { RevenueContext } from '../revenue/useRevenueContext';
import { BookingContext } from '../bookings/useBookingContext';
import { BusinessContext } from '../settings/business-profile/useBusinessContext';
import DashboardRevenueCard from './DashboardRevenueCard';
import DashboardBookingCount from './DashboardBookingCount';
import DashboardBookingsToday from './DashboardBookingsToday';
import DashboardBookingsUpcoming from './DashboardBookingsUpcoming';
import TopServices from '../analytics/overview/TopServices';
import DashboardWelcome from './DashboardWelcome';
import tablegrass from '../../assets/images/dashboard/Illustration1.svg';
import BaseHeader from '../../shared/Header';
import { Constants } from '../../utils/constants';
import { dateFromToday, isSubscriptionEnabled } from '../../utils/helpers';
import Loader from '../../shared/Loader';
import {BookingStateObjectType} from '../../utils/types/booking_types';
import {AdminAttributeType} from '../../utils/types/auth_types';

const HomeHeader = () => {
  const { state: authState, isAdmin } = useContext(AuthContext);

  const userName = () => {
    const { first_name } = authState.user;
    return first_name;
  };

  const showAfterWelcomeMsg = () => {
    if (!isAdmin() || (isAdmin() && authState.currentBusiness.attributes.onboarding_tasks_completed)) {
      return 'We’ve got some things to show you today';
    }

    return 'Welcome to Corna';
  };

  return (
    <Fragment>
      <h4 className="c-header__title">Hello {userName()},</h4>
      <p className="c-header__subtitle">{showAfterWelcomeMsg()}</p>
    </Fragment>
  );
};

const Home = () => {
  const [bookingCountGraph, setBookingCountGraph] = useState({});
  const [topServices, setTopServices] = useState([]);
  const [bookingsToday, setBookingsToday] = useState<BookingStateObjectType>({} as BookingStateObjectType);
  const [bookingsWeek, setBookingsWeek] = useState<BookingStateObjectType>({} as BookingStateObjectType);
  const { state: authState, isAdmin } = useContext(AuthContext);
  const { getAnalyticsData } = useContext(BusinessContext);
  const { revenueList } = useContext(RevenueContext);
  const { bookingList } = useContext(BookingContext);

  useEffect(() => {
    (async () => {
      const result = await getAnalyticsData();
      if (Object.keys(result).length === 0) return;

      setBookingCountGraph(result.completed_bookings_by_date);
      setTopServices(result.top_services_by_booking);

      const today = await bookingList();
      if (Object.keys(today as BookingStateObjectType).length === 0) return;
      setBookingsToday(today as BookingStateObjectType);

      const week =
        await bookingList(
          undefined,
          undefined,
          'upcoming',
          dateFromToday(1),
          dateFromToday(8)
        );

      if (Object.keys(week as BookingStateObjectType).length === 0) return;
      setBookingsWeek(week as BookingStateObjectType);

      if(isAdmin()) {
        await revenueList();
      }
    })();
  }, [authState.currentBusiness]);

  const bookableAdminAttendant = (isAdmin() && (authState.user as AdminAttributeType).bookable) || false;

  const showRevenueCard = () => {
    return isAdmin() && !(authState.user as AdminAttributeType).bookable;
  };

  const showMoreAnalytics = () => {
    if (isSubscriptionEnabled) {
      return isAdmin()
        && authState.user.subscription?.attributes.plan_code === process.env.REACT_APP_PAYSTACK_PLAN_PREMIUM_CODE;
    }

    return isAdmin();
  };

  const DATA_TYPE = [
    {
      header: showRevenueCard() ? 'Your Sales' : 'Your Completed Bookings',
      title: 'You’ve not had a booking yet',
      description: 'Looking forward to your bookings',
      type: Constants.Analytics.Revenue
    },
    {
      header: 'Today’s Bookings',
      title: 'No bookings today',
      description: 'You don’t have any services booked for today.',
      type: Constants.Analytics.BookingsToday
    },
    {
      header: 'Upcoming Bookings',
      title: 'No upcoming bookings',
      description: 'You don’t have any upcoming bookings',
      type: Constants.Analytics.BookingsUpcoming
    },
    {
      header: 'Top 3 Services',
      title: 'You’ve not any bookings in the last week',
      description: 'Looking forward to your bookings',
      type: Constants.Analytics.TopServices
    }
  ];

  const hasSetData = Object.keys(bookingCountGraph).length > 0 &&
      Object.keys(bookingsToday).length > 0 &&
      Object.keys(bookingsWeek).length > 0;

  return (
    <Fragment>
      <BaseHeader header={<HomeHeader />} />
      <div className="tab-grass-row">
        <img src={tablegrass} alt="tablegrass" className="tablegrass" />
      </div>
      {
        hasSetData ?
          <Fragment>
            {isAdmin() && <DashboardWelcome />}

            <div className="dashboard-empty__box">
              {
                DATA_TYPE.map((item, key) => {
                  if (item.type === Constants.Analytics.Revenue) {
                    return showRevenueCard() ?
                      <DashboardRevenueCard {...item} key={key} /> :
                      Object.keys(bookingCountGraph).length > 0 && <DashboardBookingCount {...item} data={bookingCountGraph} key={key} />;
                  }
                  if (item.type === Constants.Analytics.BookingsToday) {
                    return Object.keys(bookingsToday).length > 0 &&
                      <DashboardBookingsToday
                        {...item}
                        bookableAdminAttendant={bookableAdminAttendant}
                        data={bookingsToday}
                        key={key}
                      />;
                  }

                  if (item.type === Constants.Analytics.BookingsUpcoming) {
                    return Object.keys(bookingsWeek).length > 0 &&
                      <DashboardBookingsUpcoming
                        {...item}
                        bookableAdminAttendant={bookableAdminAttendant}
                        data={bookingsWeek}
                        key={key}
                      />;
                  }

                  if (item.type === Constants.Analytics.TopServices) {
                    return <TopServices {...item} data={topServices} showMoreAnalytics={showMoreAnalytics()} key={key} />;
                  }
                })
              }
            </div>
          </Fragment> : <Loader />
      }
    </Fragment>
  );
};

export default Home;
