import { Fragment, useContext, useEffect, useState } from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BaseHeader from '../../shared/Header';
import CPageCard from '../../shared/PageCard';
import {getAvatar, getObjectId} from '../../utils/helpers';
import {
  convertDurationToHrsAndMins,
  convertTimeTo12HourFormat,
  formatRatingScore,
  pluralize,
  weekMonthDayYear
} from '../../utils/stringManipulation';
import CButton from '../../shared/forms/Button';
import Rating from 'react-rating';
import starEmptyIcon from '../../assets/images/star-empty-icon.svg';
import starIcon from '../../assets/images/star-icon.svg';
import { AuthContext } from '../../contexts/useAuthContext';
import {BookingContext} from './useBookingContext';
import { Constants } from '../../utils/constants';
import CConfirmationModal from '../../shared/forms/ConfirmationModal';
import CFormTextarea from '../../shared/forms/Textarea';
import Loader from '../../shared/Loader';
import {BookingAttributeType} from '../../utils/types/booking_types';
import CModal from '../../shared/forms/Modal';

const BookingBreadCrumb = ({ attributes }: {attributes: BookingAttributeType}) => {
  const date = weekMonthDayYear(attributes.date, attributes.business_timezone);

  return (
    <Fragment>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/bookings' }}>
          Bookings
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="capitalize"
          linkAs={Link}
          linkProps={{ to: `/bookings?from=${date}&to=${date}` }}
        >
          {date}
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="capitalize">
          {`${attributes.service_name} - ${attributes.customer_name}`}
        </Breadcrumb.Item>
      </Breadcrumb>
    </Fragment>
  );
};

interface InformationProps {
  heading?: boolean;
  title: string;
  value: string | undefined;
}

const Information = ({ heading = false, title, value }: InformationProps) => {
  return <Fragment>
    <small>{title}</small>
    <p className={`text-dark ${heading ? '' : 'mb-3'}`}>{value}</p>
  </Fragment>;
};

const BookingDetails = () => {
  const [bookingAttributes, setBookingAttributes] = useState<BookingAttributeType>({} as BookingAttributeType);
  const [loading, setLoading] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [customerRating, setCustomerRating] = useState(0);
  const [businessRating, setBusinessRating] = useState(0);
  const [bookingId, setBookingId] = useState<number>();
  const [customerRatingNote, setCustomerRatingNote] = useState('');
  const [businessRatingNote, setBusinessRatingNote] = useState('');
  const [cancellationNote, setCancellationNote] = useState('');
  const [showCancellationNote, setShowCancellationNote] = useState(false);

  const { isAdmin, state: authState } = useContext(AuthContext);
  const { getBooking, rateBooking, cancelBooking } = useContext(BookingContext);

  useEffect(() => {
    (async () => {
      const id = getObjectId();

      setBookingId(id as number);
      const result = await getBooking(id as number);

      if (result) {
        const attributes = result.attributes;

        setBookingAttributes(attributes);
        setCustomerRating(attributes.rating_score);
        setBusinessRating(attributes.business_rating_score);
        setCustomerRatingNote(attributes.rating_note);
        setBusinessRatingNote(attributes.business_rating_note);
      }
    })();
  }, [refetch]);

  const timeSlot = (timezone: string) => {
    if (!bookingAttributes.date) return;

    const startTime = convertTimeTo12HourFormat(bookingAttributes.date, timezone);
    const finishTime = convertTimeTo12HourFormat(bookingAttributes.finish_time, timezone);

    return `${startTime} - ${finishTime}`;
  };

  const statusColor = () => {
    switch (bookingAttributes.status) {
    case Constants.Statuses.Booking.Unconfirmed:
      return 'color-amber';
    case Constants.Statuses.Booking.Completed:
    case Constants.Statuses.Booking.Confirmed:
    case Constants.Statuses.Booking.Done:
    case Constants.Statuses.Booking.Rated:
      return 'color-primary';
    default:
      return 'color-error';
    }
  };

  const status = () => {
    switch (bookingAttributes.status) {
    case Constants.Statuses.Booking.Unconfirmed:
      return Constants.Statuses.Booking.State.Pending;
    case Constants.Statuses.Booking.Completed:
    case Constants.Statuses.Booking.Confirmed:
    case Constants.Statuses.Booking.Done:
    case Constants.Statuses.Booking.Rated:
      return Constants.Statuses.Booking.State.Paid;
    default:
      return Constants.Statuses.Booking.State.Canceled;
    }
  };

  const shouldRateBooking =
    bookingAttributes.status === Constants.Statuses.Booking.Rated ||
    bookingAttributes.status === Constants.Statuses.Booking.Completed ||
    bookingAttributes.status === Constants.Statuses.Booking.Done;

  const isCanceled = bookingAttributes.status === Constants.Statuses.Booking.Canceled;

  const handleCancelBooking = async () => {
    setLoading(true);
    try {
      await cancelBooking(bookingId as number, cancellationNote.trim());
      setLoading(false);
      setShowCancelModal(false);
      setRefetch(!refetch);
    } catch (error) {
      setLoading(false);
      setShowCancelModal(false);
    }
  };

  const handleCancellationClose = () => {
    setCancellationNote('');
    setShowCancelModal(false);
  };

  const handleRating = async () => {
    setLoading(true);
    try {
      await rateBooking(bookingId as number, {
        customer_score: customerRating,
        customer_note: customerRatingNote
      });
      setLoading(false);
      setShowRatingModal(false);
      setRefetch(!refetch);
    } catch (error) {
      setLoading(false);
      setShowRatingModal(false);
    }
  };

  const handleRatingClose = () => {
    setCustomerRating(0);
    setShowRatingModal(false);
  };

  const showRatingForm = () => {
    const isAdminAttendant = isAdmin() && bookingAttributes.attendant_details?.id.includes('admin');
    const isAttendant = !isAdmin() && bookingAttributes.attendant_details?.id.includes('employee');

    return isAdminAttendant || isAttendant;
  };

  const ratingMessage = () => {
    if (shouldRateBooking) {
      return <Fragment>
        {bookingAttributes.rating_score ?
          <p>{customerRatingNote}</p> :
          <CFormTextarea
            value={customerRatingNote}
            onInput={setCustomerRatingNote}
          />}

        {bookingAttributes.rating_score || showRatingForm() ?
          <Rating
            className="d-flex bookings__rating my-3"
            emptySymbol={<img alt="no star" src={starEmptyIcon} className="bookings__star" />}
            fullSymbol={<img alt="star" src={starIcon} className="bookings__star" />}
            placeholderRating={formatRatingScore(customerRating)}
            placeholderSymbol={<img alt="selected-star" src={starIcon} className="bookings__star" />}
            readonly={Number(bookingAttributes.rating_score) > 0}
            onClick={(value: number) => setCustomerRating(value)}
          /> :
          <p>{`There is no rating for ${bookingAttributes.customer_first_name} yet`}</p>}

        <br />


        {!bookingAttributes.rating_score && showRatingForm() && <CButton
          medium
          disabled={!customerRating || customerRating === 0}
          onClick={() => setShowRatingModal(true)}
        >
          Submit Rating
        </CButton>}
      </Fragment>;
    } else if (isCanceled) {
      return <p>This booking has been canceled so it can not be rated</p>;
    }  else {
      return showRatingForm() ? <p>
        {`When the service is performed, you will the able to rate ${bookingAttributes.customer_first_name}`}
      </p> : <p>{`There is no rating for ${bookingAttributes.customer_first_name} yet`}</p>;
    }
  };

  const avatar = getAvatar(authState.user.profile.attributes);

  return (
    <Fragment>
      <CModal
        show={showCancelModal}
        title="Cancel booking"
        onClose={handleCancellationClose}
        footer={
          <CButton
            loading={loading}
            onClick={handleCancelBooking}
          >
            Cancel booking
          </CButton>
        }
      >
        <>
          <CFormTextarea
            placeholder={`Tell ${bookingAttributes.customer_first_name} why their booking is being canceled`}
            value={cancellationNote}
            className="mt-2"
            onInput={setCancellationNote}
          />
          <p className="mt-3">{`${bookingAttributes.customer_first_name} will be notified and refunded.`}</p>
          {!isAdmin() && <p>{`${bookingAttributes.admin_first_name} will also be notified.`}</p>}
        </>
      </CModal>
      <CConfirmationModal
        show={showRatingModal}
        type="rating"
        cta="Submit"
        hasStandardNote={false}
        loading={loading}
        customNote={
          <Fragment>
            <p>You can not edit this rating.</p>
            <p>{`Are you sure you want to give ${bookingAttributes.customer_first_name} ${pluralize(customerRating, 'star')} for this booking?`}</p>
          </Fragment>
        }
        onClick={handleRating}
        onClose={handleRatingClose}
      />
      <CModal
        show={showCancellationNote}
        title="Cancellation note"
        onClose={() => setShowCancellationNote(false)}
        footer={
          <div>
            <CButton loading={loading as boolean} small onClick={() => setShowCancellationNote(false)}>
              Ok
            </CButton>
          </div>
        }
      >
        {bookingAttributes.cancellation_note}
      </CModal>

      {Object.keys(bookingAttributes).length === 0 ? <Loader /> :
        <Fragment>
          <BaseHeader header={<BookingBreadCrumb attributes={bookingAttributes}/>} />

          <CPageCard>
            <CPageCard.Title>
              <h5>Customer Information</h5>
              <div className="c-sidebar__user p-0 d-flex">
                <div className="col-1 px-0">
                  <div className="d-flex">
                    <img src={avatar} alt="user avatar" className="bookings__img" />
                  </div>
                </div>

                <div className="col-11 col-lg-10 px-0">
                  <div className="d-flex">
                    <div className="col-4">
                      <Information
                        heading={true}
                        title="Customer name"
                        value={bookingAttributes.customer_name}/>
                    </div>
                    <div className="col-4 px-0">
                      <Information
                        heading={true}
                        title="Phone number"
                        value={bookingAttributes.customer_contact_number}
                      />
                    </div>
                    <div className="col-4 px-0">
                      <Information
                        heading={true}
                        title="Email address"
                        value={bookingAttributes.customer_email}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CPageCard.Title>
            <CPageCard.Content>
              <Row>
                <Col lg={3} xl={3}>
                  <div>
                    <h5>Service Information</h5>
                    <Information title="Service" value={bookingAttributes.service_name} />
                    <Information
                      title="Price"
                      value={bookingAttributes.service_amount}
                    />
                    <div className="d-flex">
                      <div className="pe-lg-3 pe-4">
                        <Information
                          title="Service Duration"
                          value={convertDurationToHrsAndMins(bookingAttributes.service_duration)}
                        />
                      </div>
                      <div>
                        <Information title="Service Category" value={bookingAttributes.category_name} />
                      </div>
                    </div>
                    <Information
                      title="Service assigned to"
                      value={bookingAttributes.attendant_details?.name}
                    />
                  </div>
                </Col>
                <Col lg={1} className="profile__form-vr" />
                <Col lg={4} xl={4}>
                  <div>
                    <h5>Booking Information</h5>
                    <Information
                      title="Date"
                      value={weekMonthDayYear(bookingAttributes.date, bookingAttributes.business_timezone)}
                    />
                    <Information title="Time" value={timeSlot(bookingAttributes.business_timezone)} />
                    {bookingAttributes.note && <Information
                      title="Note"
                      value={bookingAttributes.note}
                    />}
                    <small>Payment Status</small>
                    <p className="mb-5">
                      <strong className={statusColor()}>{status()}</strong>
                      {status() === 'Canceled' && bookingAttributes.cancellation_note &&
                        <span
                          className="text-dark small bookings__cancellation-note-link"
                          onClick={() => setShowCancellationNote(true)}
                        > — View note
                        </span>
                      }
                    </p>

                    <br />
                    {(showRatingForm() || isAdmin()) && !shouldRateBooking && !isCanceled &&
                      <CButton
                        color="regular"
                        medium
                        onClick={() => setShowCancelModal(true)}
                      >
                        Cancel Booking
                      </CButton>}
                  </div>
                </Col>
                <Col lg={1} className="profile__form-vr" />
                <Col lg={3} xl={3}>
                  <div>
                    <h5>{`${showRatingForm() ? 'Rate this customer' : 'Customer rating'}`}</h5>
                    {showRatingForm() && shouldRateBooking && <small>Rate customers to help us improve the Corna experience</small>}
                    {showRatingForm() && shouldRateBooking && <p className="text-dark mt-3 mb-2">
                      {`How was your experience with ${bookingAttributes.customer_first_name}?`}
                    </p>}
                    {ratingMessage()}
                  </div>

                  {businessRating > 0 && <div className="mt-3">
                    <h5>{`${bookingAttributes.business_name} rating`}</h5>
                    <div className="d-flex align-items-start small">
                      <img src={starIcon}  alt="customer's overall rating" className="analytics-customer-rating" />
                      <span className="ps-1">{formatRatingScore(businessRating)}</span>
                    </div>

                    <p>{businessRatingNote}</p>
                  </div>}
                </Col>
              </Row>
            </CPageCard.Content>
          </CPageCard>
        </Fragment>
      }
    </Fragment>
  );
};

export default BookingDetails;
