import {useContext, useEffect, useState} from 'react';
import CModal from '../../shared/forms/Modal';
import CButton from '../../shared/forms/Button';
import CFormInput from '../../shared/forms/Input';
import { ServiceContext } from './useServiceContext';
import { BusinessContext } from '../settings/business-profile/useBusinessContext';

interface Props {
  show: boolean,
  onClose: () => void,
  id?: number,
  name?: string
}

type FormErrorType = {
  name?: string[]
}

const CategoryData = ({ show, onClose, id, name }: Props) => {
  const [category, setCategory] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<FormErrorType>({});
  const { addCategory, editCategory, refetchServices } = useContext(
    ServiceContext
  );
  const { getBusiness } = useContext(BusinessContext);

  useEffect(() => {
    if (name) setCategory(name);
  }, [show]);

  const setCategoryValue = async () => {
    const business = await getBusiness();
    setLoading(true);

    try {
      const result = !id
        ? await addCategory(category)
        : await editCategory(id, category);
      setLoading(false);
      if (result) {
        refetchServices(true);
        closeModal();
      }
    } catch (error) {
      business.attributes.set_up_complete ? setFormError(error as FormErrorType) : closeModal();
      setLoading(false);
    }
  };

  const closeModal = () => {
    setFormError({});
    setCategory('');
    onClose();
  };

  const modalParagraph = () => {
    if (!id) {
      return 'Categories allow you group your services.';
    } else {
      return 'Edit the name of your category.';
    }
  };

  const disabled = !category || (show && category == name);

  return (
    <CModal
      show={show}
      title={!id ? 'Create Category' : 'Edit Category'}
      onClose={closeModal}
      footer={
        <CButton
          loading={loading}
          disabled={disabled}
          onClick={setCategoryValue}
        >
          {!id ? 'Create Category' : 'Update Category'}
        </CButton>
      }
    >
      <p className="p-3">{modalParagraph()}</p>
      <CFormInput
        placeholder="Category"
        value={category}
        errors={formError['name']}
        id="name"
        onInput={setCategory}
      />
    </CModal>
  );
};

export default CategoryData;
