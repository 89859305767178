import { useContext, useEffect, useState } from 'react';
import CModal from '../../../shared/forms/Modal';
import CButton from '../../../shared/forms/Button';
import CFormInput from '../../../shared/forms/Input';
import CSearchableSelect from '../../../shared/forms/SearchableSelect';
import { setFormValue, getFormValue } from '../../../utils/formHelpers';
import { StaffContext } from './useStaffContext';
import { Col, Row } from 'react-bootstrap';
import {JobRoleType, StaffAttributeType, StaffType} from '../../../utils/types/staff_types';
import {SelectOptionType} from '../../../utils/types/util_types';

interface Props {
  show: boolean;
  onClose: () => void;
  attributes?: StaffAttributeType;
}

type FormErrorKey =
  'first_name' |
  'last_name' |
  'email' |
  'job_role.name';
type FormErrorType = Record<FormErrorKey, string[]>

const StaffData = ({ show, onClose, attributes = {} as StaffAttributeType }: Props) => {
  const [staffForm, setStaffForm] = useState({});
  const [roles, setRoles] = useState<JobRoleType[]>([]);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<FormErrorType>({} as FormErrorType);
  const { addStaff, editStaff, roleList } = useContext(StaffContext);

  useEffect(() => {
    (async () => {
      setFormError({} as FormErrorType);
      if (!show) return;

      const values = await roleList();

      if (!values || values.length === 0) return;

      const roleValues = values.map(({ attributes }: StaffType) => ({
        value: attributes.id,
        label: attributes.name
      }));
      setRoles(roleValues);
    })();
  }, [show]);

  useEffect(() => {
    if (!attributes || Object.keys(attributes).length === 0) return;

    setFormValue(setStaffForm, 'firstName', attributes.first_name);
    setFormValue(setStaffForm, 'lastName', attributes.last_name);
    setFormValue(setStaffForm, 'email', attributes.email);
    setFormValue(setStaffForm, 'role', {
      name: attributes.job_role.attributes.name,
      id: attributes.job_role.attributes.id
    });
  }, [attributes]);

  const formData = {
    firstName: getFormValue(staffForm, 'firstName'),
    lastName: getFormValue(staffForm, 'lastName'),
    email: getFormValue(staffForm, 'email'),
    role: getFormValue(staffForm, 'role')
  };

  const inviteStaff = async () => {
    setLoading(true);
    try {
      const result =
        attributes?.id === undefined
          ? await addStaff(formData)
          : await editStaff(attributes.id, formData);
      setLoading(false);
      if (result) closeModal();
    } catch (error) {
      setFormError(error as FormErrorType);
      setLoading(false);
    }
  };

  const closeModal = () => {
    clearForm();
    onClose();
  };

  const clearForm = () => {
    setFormValue(setStaffForm, 'firstName', '');
    setFormValue(setStaffForm, 'lastName', '');
    setFormValue(setStaffForm, 'email', '');
    setFormValue(setStaffForm, 'role', '');
  };

  return (
    <CModal
      show={show}
      title={attributes.id === undefined ? 'Add new Staff' : 'Edit Staff'}
      onClose={closeModal}
      footer={
        <CButton loading={loading} onClick={inviteStaff}>
          {attributes.id === undefined ? 'Invite Staff' : 'Update Staff'}
        </CButton>
      }
    >
      <Row className="mt-4">
        <Col md={6}>
          <CFormInput
            placeholder="First name"
            value={
              attributes.id === undefined
                ? getFormValue(staffForm, 'firstName')
                : attributes.first_name
            }
            errors={formError['first_name']}
            onInput={value => setFormValue(setStaffForm, 'firstName', value)}
          />
        </Col>
        <Col md={6}>
          <CFormInput
            placeholder="Last name"
            value={
              attributes.id === undefined
                ? getFormValue(staffForm, 'lastName')
                : attributes.last_name
            }
            errors={formError['last_name']}
            onInput={value => setFormValue(setStaffForm, 'lastName', value)}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={12}>
          <CFormInput
            placeholder="Email address"
            value={
              attributes.id === undefined
                ? getFormValue(staffForm, 'email')
                : attributes.email
            }
            errors={formError['email']}
            onInput={value => setFormValue(setStaffForm, 'email', value)}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={12}>
          <CSearchableSelect
            placeholder="Role"
            options={roles}
            defaultValue={
              (attributes?.id  && {
                label: attributes.job_role.attributes.name,
                value: attributes.job_role.attributes.id
              }) as SelectOptionType
            }
            errors={formError['job_role.name']}
            onChange={value => setFormValue(setStaffForm, 'role', value)}
          />
        </Col>
      </Row>
    </CModal>
  );
};

export default StaffData;
