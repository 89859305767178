import {createContext, useReducer, useContext, PropsWithChildren} from 'react';
import { Constants } from '../../utils/constants';
import {initializeState, handleError} from '../../utils/helpers';
import {customerBlock, customerUnblock, getCustomerList, getCustomerRatingList} from '../../utils/apiCalls/analytics';
import { AuthContext } from '../../contexts/useAuthContext';
import {ApiErrorType} from '../../utils/types/util_types';
import {
  AnalyticsActionType,
  AnalyticsStateObjectType,
  AnalyticsCustomerStateObjectType
} from '../../utils/types/analytics_types';

const initialState = {
  customers: {
    hasCustomers: false,
    updateState: false,
    items: [],
    totalCount: 0,
    pageNumber: 1,
    ratings: {
      items: [],
      totalCount: 0,
      pageNumber: 1,
    }
  } 
};

const AnalyticsReducer = (state: AnalyticsStateObjectType, action: AnalyticsActionType) => {
  switch (action.type) {
  case Constants.Actions.Analytics.State:
    return {
      ...state,
      customers: { ...action.payload }
    };
  default:
    return { ...state };
  }
};

const actions = (dispatch: (_: any) => void, state: AnalyticsStateObjectType) => {
  const { state: authState } = useContext(AuthContext);

  const resetUpdateState = (payload?: AnalyticsCustomerStateObjectType | boolean) => {
    dispatch({
      type: Constants.Actions.Analytics.ResetUpdateState,
      payload
    });
  };
  const returnValue = {
    customerList: async (pageNumber: number, query: string = '') => {
      const { user, currentBusinessId } = authState;
      try {
        resetUpdateState(true);
        const customers = await getCustomerList(
          user,
          currentBusinessId,
          pageNumber,
          query
        );

        const { data } = customers;

        const payload = {
          hasCustomers: data.has_items,
          items: data.data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page
        };

        dispatch({
          type: Constants.Actions.Analytics.State,
          payload
        });
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    blockCustomer: async (customerId: number) => {
      const { user, currentBusinessId } = authState;
      try {
        resetUpdateState(true);
        await customerBlock(
          user,
          currentBusinessId,
          customerId
        );

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    unblockCustomer: async (customerId: number) => {
      const { user, currentBusinessId } = authState;
      try {
        resetUpdateState(true);
        await customerUnblock(
          user,
          currentBusinessId,
          customerId
        );

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    customerRatingList: async (customerId: number, pageNumber: number = 1) => {
      const { user, currentBusinessId } = authState;
      try {
        resetUpdateState(true);
        const ratings = await getCustomerRatingList(
          user,
          currentBusinessId,
          customerId,
          pageNumber
        );

        const { data } = ratings;

        const ratingPayload = {
          items: data.data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page
        };

        const payload = {...state.customers, ratings: ratingPayload};

        dispatch({
          type: Constants.Actions.Analytics.State,
          payload
        });
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },
  };
  return returnValue;
};

type AnalyticsContextActions = ReturnType<typeof actions>
type AnalyticsContextType = {state: AnalyticsStateObjectType} & AnalyticsContextActions
export const AnalyticsContext = createContext<AnalyticsContextType>({} as AnalyticsContextType);

export default function AnalyticsProvider({ children, value }: PropsWithChildren<{value?: AnalyticsStateObjectType}>) {
  const [state, dispatch] = useReducer(
    AnalyticsReducer,
    initializeState(
      Constants.StorageKey.Analytics,
      {...initialState, ...(value as AnalyticsStateObjectType ?? {})}
    )
  );

  return (
    <AnalyticsContext.Provider value={{ state, ...actions(dispatch, state) }}>
      {children}
    </AnalyticsContext.Provider>
  );
}
