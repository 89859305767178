import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler, TooltipItem,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import { chartLabelFormatter } from '../stringManipulation';

interface Props {
  title: string,
  labels: string[],
  yAxisData: number[],
  labelFormat: string,
  totalValue?: string,
  dateFilter?: string,
  noPadding?: boolean,
  showBorder?: boolean,
  height: string,
  empty?: boolean
}

const LineChart = ({
  title,
  labels,
  yAxisData,
  labelFormat,
  totalValue,
  dateFilter,
  noPadding = false,
  showBorder = true,
  height,
  empty
}: Props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
  );

  const roundUpNearest10 = (num: number) => {
    return Math.ceil(num / 10) * 10;
  };

  const maxRange = Math.max(...yAxisData);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: '#fff'
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          borderColor: '#fff'
        },
        ticks: {
          precision: 0,
          min: Math.min(...yAxisData),
          max: maxRange,
          stepSize: maxRange === 0 ? 1 : roundUpNearest10(maxRange)/10,
          callback: function(label: string) {
            return chartLabelFormatter(label, labelFormat);
          }
        },
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#737a91',
        titleFont: { weight: 'normal' },
        titleMarginBottom: -1,
        borderColor: '#dfe2e6',
        borderWidth: 1,
        displayColors: false,
        yAlign: 'no-transform',
        callbacks: {
          labelTextColor: function() {
            return '#222222';
          },
          label: function(context: TooltipItem<any>) {
            return chartLabelFormatter(context.parsed.y.toLocaleString(), labelFormat);
          }
        }
      },
    }
  };

  const data: any = {
    labels,
    datasets: [
      {
        fill: 'origin',
        data: yAxisData,
        borderColor: '#3ab795',
        backgroundColor: '#d4e9e2',
        pointBackgroundColor: '#3ab795',
        pointRadius: 6,
        pointHoverRadius: 6,
        pointBorderColor: '#3ab795'
      }
    ],
  };

  return (
    <div>
      {!empty && <div className={`${showBorder ? 'c-table__box' : ''}`}>
        <section className={`c-table__title ${noPadding ? 'c-table__title-no-padding' : ''}`}>
          <span className="text-dark">
            {title && (
              <div>
                <strong>{title}</strong>
                <small className="ms-2 color-gray">{dateFilter}</small>
                <br />
              </div>)}
            <h4 className="color-primary">{totalValue}</h4>
          </span>
        </section>
        <div style={{height: height}} className="my-3">
          <Line
            data={data}
            options={options}
            type="line"
          />
        </div>
      </div>}
    </div>
  );
};

export default LineChart;
