import { useContext, useEffect, useState } from 'react';
import CModal from '../../../shared/forms/Modal';
import CButton from '../../../shared/forms/Button';
import CFormInput from '../../../shared/forms/Input';
import { setFormValue, getFormValue } from '../../../utils/formHelpers';
import { Col, Row } from 'react-bootstrap';
import { Constants } from '../../../utils/constants';
import CSearchableSelect from '../../../shared/forms/SearchableSelect';
import { PlanContext } from '../plans/usePlanContext';
import {BankOptionType} from '../../../utils/types/subscription_types';

interface Props {
  show: boolean,
  refetchDetails: boolean,
  setRefetchDetails: (_: any) => void,
  onClose: () => void
}

const BankDetailData = ({ show, refetchDetails, setRefetchDetails, onClose }: Props) => {
  const [bankDetailForm, setBankDetailForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [accountName, setAccountName] = useState('');
  const [formError, setFormError] = useState<string | string[]>('');
  const [banks, setBanks] = useState([]);

  const accountNumberCount = 10;

  const { retrieveAccountName, addBankDetail } = useContext(PlanContext);

  useEffect(() => {
    const bankList = getBankList().map((bank: BankOptionType) => ({
      value: bank.bank_code,
      label: bank.bank_name
    }));
    setBanks(bankList);
  }, []);

  useEffect(() => {
    (async () => {
      if (!getFormValue(bankDetailForm, 'bankCode') || !getFormValue(bankDetailForm, 'accountNumber') ||
        getFormValue(bankDetailForm, 'accountNumber').length < accountNumberCount)
        return;

      await fetchAccountName();
    })();
  }, [getFormValue(bankDetailForm, 'bankCode'), getFormValue(bankDetailForm, 'accountNumber')]);

  const closeModal = () => {
    setFormError('');
    setAccountName('');
    clearForm();
    onClose();
  };

  const fetchAccountName = async () => {
    setFormError('');
    setLoading(true);
    try {
      const result = await retrieveAccountName(
        getFormValue(bankDetailForm, 'accountNumber'),
        getFormValue(bankDetailForm, 'bankCode')
      );
      setAccountName(result.data);
      setLoading(false);
      setDisabled(false);
    } catch (error) {
      setFormError([error] as string[]);
      setLoading(false);
    }
  };

  const clearForm = () => {
    setFormValue(setBankDetailForm, 'bankName', '');
    setFormValue(setBankDetailForm, 'accountNumber', '');
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addBankDetail({
        accountName: accountName,
        accountNumber: getFormValue(bankDetailForm, 'accountNumber'),
        bankCode: getFormValue(bankDetailForm, 'bankCode')
      });
      setLoading(false);
      setRefetchDetails(!refetchDetails);
      closeModal();
    } catch (error) {
      setLoading(false);
      closeModal();
    }
  };

  const presetData = () => {
    return JSON.parse(localStorage.getItem(Constants.StorageKey.PresetData) as string);
  };

  const getBankList = () => {
    if(presetData()) return presetData().banks;
  };

  return (
    <CModal
      show={show}
      title="Add bank information"
      onClose={closeModal}
      footer={
        <CButton loading={loading} disabled={disabled} onClick={handleSubmit}>
          Add bank detail
        </CButton>
      }
    >
      <Row className="mt-4">
        <Col md={12}>
          <CSearchableSelect
            placeholder="Bank name"
            defaultValue={getFormValue(bankDetailForm, 'bankCode')}
            onChange={value => setFormValue(setBankDetailForm, 'bankCode', value)}
            options={banks}
            isValidNewOption={() => false}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={12}>
          <CFormInput
            placeholder="Account number"
            value={getFormValue(bankDetailForm, 'accountNumber')}
            maxLength={accountNumberCount}
            onInput={value => setFormValue(setBankDetailForm, 'accountNumber', value)}
            errors={formError as string[]}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={12} className="ms-1">
          <h6>{accountName && <p>{accountName}</p>}</h6>
        </Col>
      </Row>
    </CModal>
  );
};

export default BankDetailData;
