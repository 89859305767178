import { Fragment, useContext, useEffect, useState } from 'react';
import EmptyState from '../../../shared/EmptyState';
import { Constants } from '../../../utils/constants';
import CConfirmationModal from '../../../shared/forms/ConfirmationModal';
import StaffData from './StaffData';
import { CTable, CTableData, CTableRow } from '../../../shared/CTable';
import CButton from '../../../shared/forms/Button';
import { AuthContext } from '../../../contexts/useAuthContext';
import { StaffContext } from './useStaffContext';
import { pluralize, titleize } from '../../../utils/stringManipulation';
import {
  JobRoleType,
  StaffAttributeType,
  StaffType,
  StaffStateObjectType
} from '../../../utils/types/staff_types';
import {FilterItemType} from '../../../utils/types/util_types';
import {useHistory} from 'react-router-dom';
import {successToast} from '../../../utils/helpers';

interface Props {
  onAddStaff: () => void;
}

const StaffTable = ({ onAddStaff }: Props) => {
  const { state: authState, getEmployee, isAdmin } = useContext(AuthContext);
  const {
    state,
    refetchStaff,
    staffList,
    deleteStaff,
    resendInvite,
    reactivateStaff,
    roleList
  } = useContext(StaffContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [staffConfig, setStaffConfig] = useState<StaffStateObjectType>(state);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [attributeValues, setAttributeValues] = useState<StaffAttributeType>({} as StaffAttributeType);
  const [customNote, setCustomNote] = useState<undefined | string>(undefined);
  const [roles, setRoles] = useState<JobRoleType[]>([]);

  const history = useHistory();
  const historyState = (history.location.state as any);

  useEffect(() => {
    (async () => {
      if (historyState?.from === 'from-service-page' && historyState?.action === 'add') {
        successToast('Your service has been saved as a draft');
        window.history.replaceState({}, document.title);
      }

      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, [authState.currentBusiness]);

  useEffect(() => {
    (async () => {
      const { items = [], totalCount = 0, pageNumber, refetch } = state;
      setStaffConfig({ ...staffConfig, items, totalCount, pageNumber });
      if (refetch) {
        refetchStaff();
        await fetchData(pageNumber);
      }
    })();
  }, [state]);

  useEffect(() => {
    (async () => {
      const values = await roleList();
      if (!values || values.length === 0) return;

      const roleValues = values.map(({ attributes }: StaffType) => (attributes.name));
      setRoles(roleValues);
    })();
  }, []);

  const fetchData = async (
    pageNumber: number = 1,
    query = '',
    filterItems = {} as FilterItemType
  ) => {
    await staffList(pageNumber, query, filterItems);
  };

  const tableConfig = {
    title: Constants.Table.Title.Staff,
    theadData: Constants.Table.Header.Staff,
    totalCount: staffConfig.totalCount,
    pageRange: Constants.PerPageCount.Staff,
    filterItems: [
      {
        title: Constants.Filter.Status.Value,
        submenu: [
          titleize(Constants.Statuses.Staff.Pending),
          titleize(Constants.Statuses.Staff.Active),
          titleize(Constants.Statuses.Staff.Deactivated)
        ]
      },
      {
        title: Constants.Filter.Role.Value,
        submenu: roles
      }
    ],
    OnSearch: fetchData,
    onPageChange: async (page: number, query: string, filterItems = {} as FilterItemType) => {
      if (staffConfig.totalCount > 0) await fetchData(page, query, filterItems);
    }
  };

  const prepareEdit = (attributes: StaffAttributeType) => {
    setAttributeValues(attributes);
    setShowEditModal(true);
  };

  const prepareStatusChange = async (attributes: StaffAttributeType) => {
    setAttributeValues(attributes);

    if (attributes.status === Constants.Statuses.Staff.Active) {
      const staff = await getEmployee(attributes);
      const bookingCount = staff.upcoming_bookings_count;
      if (bookingCount > 0) {
        const note =
          `${staff.first_name} has ${pluralize(bookingCount, 'upcoming booking')} which will be canceled.`;
        setCustomNote(note);
      } else {
        setCustomNote(undefined);
      }
    }

    setShowConfirmationModal(true);
  };

  const handleStaffStatusChange = async () => {
    setLoading(true);

    if (attributeValues.status === Constants.Statuses.Staff.Deactivated) {
      await reactivateStaff(attributeValues.id);
    } else {
      await deleteStaff(attributeValues);
    }

    setLoading(false);
    setShowConfirmationModal(false);
  };

  const statusColor = (attributes: StaffAttributeType) => {
    switch (attributes.status) {
    case Constants.Statuses.Staff.Deactivated:
      return 'color-error';
    case Constants.Statuses.Staff.Pending:
      return 'color-amber';
    case Constants.Statuses.Staff.MarkedForDeactivation:
      return 'color-gray';
    default:
      return 'color-primary';
    }
  };

  const statusChangeOptions = (attributes: StaffAttributeType) => {
    switch (attributes.status) {
    case Constants.Statuses.Staff.Deactivated:
      return Constants.Statuses.Staff.Action.Reactivate;
    case Constants.Statuses.Staff.Pending:
      return Constants.Statuses.Staff.Action.Delete;
    default:
      return Constants.Statuses.Staff.Action.Deactivate;
    }
  };

  return (
    <div>
      {!state.hasStaff && !loading ? (
        <EmptyState
          type={Constants.Table.Empty.StaffTable}
          onClick={onAddStaff}
        />
      ) : (
        <Fragment>
          <CConfirmationModal
            show={showConfirmationModal}
            type="staff"
            loading={loading}
            cta={statusChangeOptions(attributeValues)}
            customNote={customNote}
            onClick={handleStaffStatusChange}
            onClose={() => setShowConfirmationModal(false)}
          />
          <StaffData
            show={showEditModal}
            onClose={() => setShowEditModal(false)}
            attributes={attributeValues}
          />
          <CTable {...tableConfig}>
            {staffConfig.items.map(({ attributes }, index) => (
              <CTableRow key={index}>
                <CTableData>
                  <strong>{`${attributes.first_name} ${attributes.last_name}`}</strong>
                </CTableData>
                <CTableData>
                  <small>{attributes.email}</small>
                  {attributes.profile.attributes.contact_number &&
                    <Fragment>
                      <br />
                      <small>
                        {attributes.profile.attributes.contact_number}
                      </small>
                    </Fragment>
                  }
                </CTableData>
                <CTableData>
                  <p>{attributes.job_role.attributes.name}</p>
                </CTableData>
                <CTableData>
                  <p className={statusColor(attributes)}>
                    {attributes.status}
                  </p>
                </CTableData>
                <CTableData>
                  {isAdmin() && attributes.status === 'pending' && <CButton
                    small
                    smallText
                    color="regular"
                    onClick={() => resendInvite(attributes.id)}
                  >
                    Resend Invitation
                  </CButton>}
                </CTableData>
                {isAdmin() ? (
                  <CTableData classes="actions">
                    <CButton
                      small
                      smallText
                      onClick={() => prepareEdit(attributes)}
                      disabled={attributes.status === 'pending'}
                    >
                      Edit
                    </CButton>
                    <CButton
                      small
                      smallText
                      color="transparent"
                      onClick={() => prepareStatusChange(attributes)}
                    >
                      {statusChangeOptions(attributes)}
                    </CButton>
                  </CTableData>
                ) : <CTableData />}
              </CTableRow>
            ))}
          </CTable>
        </Fragment>
      )}
    </div>
  );
};

export default StaffTable;
