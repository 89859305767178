import {ReactNode, useContext} from 'react';
import { DashboardContext } from '../contexts/useDashboardContext';
import hamburgerIcon from '../assets/images/dashboard/hamburger-icon.svg';

interface Props {
  children?: ReactNode,
  header?: ReactNode,
  title?: string
}
const BaseHeader = ({ children, header, title }: Props) => {
  const { toggleSidebar } = useContext(DashboardContext);

  return (
    <header className="c-header">
      <div className="c-header__content">
        {header}
        <div className="d-flex justify-content-between">
          <h4 className="c-header__title">{title}</h4>
          {children}
        </div>
      </div>
      <div className="c-header__hamburger">
        <button onClick={toggleSidebar}>
          <img src={hamburgerIcon} alt="hamburger" />
        </button>
      </div>
    </header>
  );
};

export default BaseHeader;
