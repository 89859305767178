import {useState, useEffect, HTMLInputTypeAttribute, ChangeEvent, MutableRefObject} from 'react';
import EyeOpenIcon from '../../assets/images/eye-open.svg';
import EyeSlashIcon from '../../assets/images/eye-slash.svg';

interface Props {
  type?: HTMLInputTypeAttribute | undefined;
  id?: string | undefined;
  value?: string | undefined;
  name?: string | undefined;
  placeholder?: string | undefined;
  errors?: string[] | undefined;
  onInput?: (_: any) => void;
  readOnly?: boolean | undefined;
  prefix?: string | undefined;
  innerRef?: MutableRefObject<null>;
  maxLength?: number | undefined;
}

const CFormInput = ({
  type = 'text',
  id,
  value,
  name,
  placeholder,
  errors,
  onInput,
  readOnly,
  prefix,
  innerRef,
  maxLength
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [hideErrors, setHideErrors] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const [inputType, setInputType] = useState<Props['type']>('');

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    setHidePassword(type === 'password');
    setInputType(type);
    // setting time type to text on load
    if (type === 'time') setInputType('text');
  }, [type]);

  useEffect(() => {
    setHideErrors(!errors);
  }, [errors]);

  const handleInput = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const { value } = e.target;
    setInputValue(value);
    onInput?.(value);
    if (value) {
      setHideErrors(true);
    }
  };

  const switchInputType = () => {
    setHidePassword(prev => !prev);
    setInputType(hidePassword ? 'text' : 'password');
  };

  const handleFocus = () => {
    if (type === 'time') setInputType('time');
  };

  const handleBlur = () => {
    if (type === 'time' && !inputValue) setInputType('text');
  };

  const allowedImgProps = { type: type };

  return (
    <div>
      <div className="c-input__box">
        <input
          className={`c-input ${inputValue ? 'label' : ''} ${
            errors ? 'c-input__error' : ''
          } ${prefix ? 'c-input__prefix--input' : ''}
          ${readOnly ? 'c-input__disabled' : ''}`}
          name={name}
          data-testid={id}
          value={inputValue}
          type={inputType}
          readOnly={readOnly}
          onChange={handleInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={innerRef}
          placeholder=""
          maxLength={maxLength}
        />
        {prefix && <div className="c-input__prefix">{prefix}</div>}
        <span className="c-input__placeholder">{placeholder}</span>

        {type === 'password' && <img
          {...allowedImgProps}
          className="c-input__eye"
          src={hidePassword ? EyeSlashIcon : EyeOpenIcon}
          alt={hidePassword ? 'password hidden' : 'password displayed'}
          onClick={switchInputType}
        />}
      </div>
      {!hideErrors && errors?.length && (
        <ul>
          {errors.map((err, index) => (
            <li key={index} className="c-form__error">
              {err}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CFormInput;
