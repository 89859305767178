import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/css/App.css';
import AllProviders from './AllProviders';
import Routes from './Routes';
import { getLocalStorageItem, setLocalStorageItem } from './utils/helpers';
import { Constants } from './utils/constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const mainState = getLocalStorageItem(Constants.StorageKey.MainKey);
  if (!mainState) setLocalStorageItem(Constants.StorageKey.MainKey, '');

  return (
    <div className="app">
      <AllProviders>
        <Routes />
        <ToastContainer style={{width: 'auto', whiteSpace: 'pre'}} />
      </AllProviders>
    </div>
  );
};

export default App;
