import {useContext, useEffect, useState} from 'react';
import CModal from '../../shared/forms/Modal';
import CButton from '../../shared/forms/Button';
import {LineItemDataType} from '../../utils/types/revenue_types';
import {Constants} from '../../utils/constants';
import {RevenueContext} from './useRevenueContext';
import {CDashboardTable, CDashboardTableData} from '../../shared/CDashboardTable';
import {CTableRow} from '../../shared/CTable';

interface Props {
  show: boolean,
  onClose: () => void,
  invoiceId: number
}

const RevenueLineItems = ({ show, onClose, invoiceId }: Props) => {
  const { state, lineItemList } = useContext(RevenueContext);

  const [lineItemConfig, setLineItemConfig] = useState<LineItemDataType>(state.lineItems ?? {});

  useEffect(() => {
    (async () => {
      const { lineItems = {} as LineItemDataType } = state;
      const { items = [], totalCount = 0, pageNumber = 1 } = lineItems;

      setLineItemConfig({ items, totalCount, pageNumber } as LineItemDataType);
    })();
  }, [show, state.lineItems]);

  const tableConfig = {
    totalCount: lineItemConfig.totalCount,
    pageRange: Constants.PerPageCount.LineItems,
    onPageChange: async (page: number) => {
      if (lineItemConfig.totalCount > 0) await fetchData(page);
    }
  };

  const fetchData = async (pageNumber = 1) => {
    await lineItemList(invoiceId, pageNumber);
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <CModal
      show={show}
      onClose={closeModal}
      footer={
        <CButton
          onClick={closeModal}
          small
        >
          Ok
        </CButton>
      }
    >
      <CDashboardTable {...tableConfig} wrapperClassName="revenue__modal-table">
        {(lineItemConfig.items ?? []).map((lineItem, index) => {
          const bookingData = lineItem.attributes.booking_data;

          return <CTableRow key={index}>
            <CDashboardTableData>
              <div className="d-flex justify-content-between align-items-center">
                <div className="text-justify">
                  <h5>{bookingData.attendant}, <span className="small fw-normal">{bookingData.date}</span></h5>
                  <span className="small">{bookingData.time}, {bookingData.service}</span>
                </div>
                <p>{lineItem.attributes.amount}</p>
              </div>
            </CDashboardTableData>
          </CTableRow>;
        })}
      </CDashboardTable>
    </CModal>
  );
};

export default RevenueLineItems;
