import { PulseLoader } from 'react-spinners';

const Loader = () => {
  return (
    <div className="d-flex w-100 justify-content-center">
      <PulseLoader color="#fbe2c5" />
    </div>
  );
};

export default Loader;
