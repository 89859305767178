import {PropsWithChildren} from 'react';
import {AuthProvider} from './contexts/useAuthContext';
import RevenueProvider from './dashboard/revenue/useRevenueContext';
import BookingProvider from './dashboard/bookings/useBookingContext';
import StaffProvider from './dashboard/settings/staff/useStaffContext';
import AnalyticsProvider from './dashboard/analytics/useAnalyticsContext';
import BusinessProvider from './dashboard/settings/business-profile/useBusinessContext';
import PlanProvider from './dashboard/settings/plans/usePlanContext';
import {AuthStateObjectType} from './utils/types/auth_types';
import {BusinessStateObjectType} from './utils/types/business_types';
import {PlanStateObjectType} from './utils/types/subscription_types';
import {BookingStateObjectType} from './utils/types/booking_types';
import {AnalyticsStateObjectType} from './utils/types/analytics_types';
import {RevenueStateObjectType} from './utils/types/revenue_types';

type AllProvidersType = {
  authState?: AuthStateObjectType;
  businessState?: BusinessStateObjectType;
  planState?: PlanStateObjectType;
  bookingState?: BookingStateObjectType;
  analyticsState?: AnalyticsStateObjectType;
  revenueState?: RevenueStateObjectType;
}

const AllProviders = ({children, value = {}}: PropsWithChildren<{value?: AllProvidersType}>) => {
  return (
    <AuthProvider value={value?.authState as AuthStateObjectType}>
      <StaffProvider>
        <RevenueProvider value={value?.revenueState as RevenueStateObjectType}>
          <BookingProvider value={value?.bookingState as BookingStateObjectType}>
            <AnalyticsProvider value={value?.analyticsState as AnalyticsStateObjectType}>
              <BusinessProvider value={value?.businessState as BusinessStateObjectType}>
                <PlanProvider value={value?.planState as PlanStateObjectType}>
                  {children}
                </PlanProvider>
              </BusinessProvider>
            </AnalyticsProvider>
          </BookingProvider>
        </RevenueProvider>
      </StaffProvider>
    </AuthProvider>
  );
};

export default AllProviders;
