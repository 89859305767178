import { useRef, useState } from 'react';
import Dropdown from 'react-multilevel-dropdown';
import CCalendar from './CCalendar';
import { numericDate } from '../utils/stringManipulation';
import { Constants, UtcTimezone } from '../utils/constants';
import { dateFromToday } from '../utils/helpers';
import { FilterItemType } from '../utils/types/util_types';
import CalendarToggle from './CalendarToggle';

type CalendarOptionType = {
  showCalendar?: boolean;
  isDateFilter?: boolean;
  showCalendarIcon?: boolean;
};

interface Props {
  values: any[];
  onChange: (obj: FilterItemType) => void;
  calendarOptions?: CalendarOptionType;
  onToggle?: () => void;
}

const CInlineFilter = ({
  values = [],
  onChange,
  calendarOptions,
  onToggle
}: Props) => {
  const [resetCalendarToggle, setResetCalendarToggle] = useState(false);

  const ref = useRef<any>(null);

  type KeyType = {
    [key: string]: string;
  };

  const handleSelect = (key: KeyType, value?: string | string[]) => {
    let v;
    let isArray;

    if (key.title === Constants.Filter.Date.Value) {
      switch (value as string) {
        case Constants.Filter.Date.Options.Today:
          v = {
            from: numericDate(
              dateFromToday().toLocaleDateString(),
              UtcTimezone
            ),
            to: numericDate(dateFromToday().toLocaleDateString(), UtcTimezone),
            custom: false
          };
          break;
        case Constants.Filter.Date.Options.Last7Days:
          v = {
            from: numericDate(
              dateFromToday(-6).toLocaleDateString(),
              UtcTimezone
            ),
            to: numericDate(dateFromToday().toLocaleDateString(), UtcTimezone),
            custom: false
          };
          break;
        case Constants.Filter.Date.Options.Last30Days:
          v = {
            from: numericDate(
              dateFromToday(-29).toLocaleDateString(),
              UtcTimezone
            ),
            to: numericDate(dateFromToday().toLocaleDateString(), UtcTimezone),
            custom: false
          };
          break;
        default:
          isArray = Array.isArray(value);

          v = {
            from: isArray
              ? (value as string[])[0]
              : numericDate(dateFromToday().toLocaleDateString(), UtcTimezone),
            to: isArray
              ? (value as string[])[1]
              : numericDate(dateFromToday().toLocaleDateString(), UtcTimezone),
            custom: true
          };
      }
    } else {
      v = value;
    }

    const obj: Record<string, any> = {};
    obj[key.title.toLowerCase()] = v;

    onChange(obj as FilterItemType);

    if (ref.current && !calendarOptions?.showCalendar) {
      ref.current.toggle();
    }
  };

  const onDateChange = (d?: Date[]) => {
    if (!d) return;

    const formattedDates = d.map((date) =>
      numericDate(date.toLocaleDateString(), UtcTimezone)
    );

    handleSelect(
      { title: Constants.Filter.Date.Value },
      formattedDates as string[]
    );
  };

  return (
    <div className="d-flex">
      <div className="c-filter__box">
        <Dropdown
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          as="span"
          title="Filter by"
          ref={ref}
          className="dropdown-toggle btn btn-primary"
          onClick={() => setResetCalendarToggle(!calendarOptions?.showCalendar)}
        >
          {values.map((el, index) => (
            <Dropdown.Item key={index} className="dropdown-item">
              {el.title}
              {el.submenu && el.submenu.length > 0 && (
                <Dropdown.Submenu position="left">
                  {el.submenu.map((submenu: string, i: number) => (
                    <Dropdown.Item
                      key={`${index}${i}`}
                      className="dropdown-item"
                      onClick={() => handleSelect(el, submenu)}
                    >
                      {submenu}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Submenu>
              )}
            </Dropdown.Item>
          ))}
        </Dropdown>
        {calendarOptions?.showCalendar && resetCalendarToggle && (
          <div className="c-filter__calendar">
            <CCalendar onDateChange={onDateChange} />
          </div>
        )}
      </div>

      <CalendarToggle
        isDateFilter={calendarOptions?.isDateFilter as boolean}
        showCalendarIcon={calendarOptions?.showCalendarIcon as boolean}
        onToggle={() => onToggle?.()}
      />
    </div>
  );
};

export default CInlineFilter;
