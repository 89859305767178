import {Fragment, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import CButton from '../../../shared/forms/Button';
import check from '../../../assets/images/plan-feature.svg';
import uncheck from '../../../assets/images/plan-feature-missing.svg';
import CConfirmationModal from '../../../shared/forms/ConfirmationModal';
import PaymentData from './PaymentData';
import {UserAttributeType} from '../../../utils/types/auth_types';

interface HeaderProps {
  name: string;
  price: string,
  interval: string
}
const PlanHeader = ({ name, price, interval }: HeaderProps) => {
  return (
    <Fragment>
      <div className="text-dark">
        <strong>{name}</strong>
      </div>
      <div className="c-header__title color-primary">
        <span>
          <small className="service__type">{'\u20A6'}</small>
          {`${price}`}
          <span className="home-text__regular--title">{` / ${interval}`}</span>
        </span>
      </div>
      <em className="small d-none d-md-block color-gray mb-3">including service charge</em>
    </Fragment>
  );
};

interface PlanCardProps {
  id: number,
  user: UserAttributeType,
  name: string,
  price: string,
  interval: string,
  description: string,
  features: ((string | boolean)[])[],
  selectedPlan: boolean,
  selectedPlanButton: boolean
}
const PlanCard = ({
  id,
  user,
  name,
  price,
  interval,
  description,
  features,
  selectedPlan,
  selectedPlanButton
}: PlanCardProps) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const customNote = (
    <Fragment>
      <span>Please confirm the Corna pricing plan that you’ve selected</span>
      <br />
      <Card className="plan-card mt-3">
        <PlanHeader name={name} price={price} interval={interval}/>
      </Card>
    </Fragment>
  );

  const displayPaymentForm = () => {
    setLoading(true);
    setShowConfirmationModal(false);
    setLoading(false);
    setShowPaymentModal(true);
  };

  return (
    <Fragment>
      <CConfirmationModal
        show={showConfirmationModal}
        cta="Confirm"
        type="plan"
        hasStandardNote={false}
        loading={loading}
        onClick={displayPaymentForm}
        customNote={customNote}
        onClose={() => setShowConfirmationModal(false)}
      />
      <PaymentData
        user={user}
        show={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        planId={id}
      />
      <Col md={4} className="mb-4 mb-md-0">
        <Card className={`home-card welcome-card-inner ${selectedPlan && 'plan-selected'}`}>
          <Card.Body>
            <PlanHeader name={name} price={price} interval={interval} />
            <p className="small my-2 mb-4 d-none d-md-block plan-description">
              {description}
            </p>
            {features.map((feature, index) => {
              return (
                <Row className="plan-card-features" key={index}>
                  <Col xs={8} className="chk-box-col">
                    <img
                      src={feature[1] ? check : uncheck}
                      alt="custom-checkbox"
                      className="d-none d-xl-block"
                    />
                    <p className={`small ${!feature[1] && 'color-gray'}`}>{feature[0]}</p>
                  </Col>
                </Row>
              );
            })}
            <div className="mt-4">
              <CButton small disabled={selectedPlanButton} onClick={() => setShowConfirmationModal(true)}>
                {`${selectedPlan ? 'Reselect plan' : 'Choose plan'}`}
              </CButton>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default PlanCard;
