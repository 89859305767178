import { useEffect, useState } from 'react';
import { EmptyStateTexts } from '../utils/constants';
import CButton from './forms/Button';

type ConfigTextType = {
  buttonText?: string,
  text: string
}

const EmptyState = ({ type, onClick }: { type: string, onClick?: () => void}) => {
  const [configText, setConfigText] = useState<ConfigTextType>({} as ConfigTextType);

  useEffect(() => {
    setConfigText(EmptyStateTexts[type]);
  }, []);

  return (
    <div className="empty-state">
      <div className="empty-image">
        <img src={require('../assets/images/tree-icon.svg').default} alt="Empty state" />
      </div>
      <div
        className="empty-text"
        dangerouslySetInnerHTML={{ __html: configText.text }}
      />
      {configText.buttonText && (
        <CButton onClick={onClick}>{configText.buttonText}</CButton>
      )}
    </div>
  );
};

export default EmptyState;
