import LineChart from '../../utils/charts/line';
import {RevenueGraphDataType} from '../../utils/types/revenue_types';

interface Props {
  revenueData: RevenueGraphDataType;
  dateFilter: string;
  empty: boolean;
}

const RevenueChart = ({revenueData, dateFilter, empty}: Props) => {
  return (
    <LineChart
      title="Your revenue"
      labels={revenueData.labels}
      yAxisData={revenueData.amounts}
      labelFormat="money"
      totalValue={`\u20A6${Number(revenueData.total_amount).toLocaleString()}`}
      dateFilter={dateFilter}
      height="270px"
      empty={empty}
    />
  );
};

export default RevenueChart;
