import { withRouter } from 'react-router-dom';
import checkEmailLogo from '../assets/images/check-email.svg';
import CCard from '../shared/CCard';
import { Constants } from '../utils/constants';

const ForgotPasswordSuccess = () => {
  return (
    <div className="c-auth__gradient">
      <CCard
        content={
          <div className="c-auth__content">
            <img src={checkEmailLogo} alt="Logo" />
            <br />
            <br />
            <h3>Password reset mail sent</h3>
            <p className="mt-2">
              We’ve sent a link to your email address. Click on it to change
              your password
            </p>
          </div>
        }
      />
      <div>
        <p className="mt-3 form-meta">
          Already have an account?&nbsp;
          <a href={Constants.Links.Auth.Path.Login} className="link-text">
            Sign in
          </a>
        </p>
      </div>
    </div>
  );
};

export default withRouter(ForgotPasswordSuccess);
