import { Fragment, useContext, useEffect, useState } from 'react';
import BaseHeader from '../../shared/Header';
import { AuthContext } from '../../contexts/useAuthContext';
import { RevenueContext } from './useRevenueContext';
import EmptyState from '../../shared/EmptyState';
import { Constants } from '../../utils/constants';
import { CTable, CTableData, CTableRow } from '../../shared/CTable';
import RevenueChart from './RevenueChart';
import CButton from '../../shared/forms/Button';
import RevenueLineItems from './RevenueLineItems';
import {monthDayYear, titleize} from '../../utils/stringManipulation';
import { dateFromToday } from '../../utils/helpers';
import {FilterItemType} from '../../utils/types/util_types';
import {RevenueAttributeType} from '../../utils/types/revenue_types';

const RevenueTable = () => {
  const [loading, setLoading] = useState(false);
  const [showLineItemsModal, setShowLineItemsModal] = useState(false);
  const [dateFilterOption, setDateFilterOption] = useState('');
  const [invoiceId, setInvoiceid] = useState<number>();

  const { state: authState } = useContext(AuthContext);
  const { state, revenueList, lineItemList } = useContext(RevenueContext);

  const timezone = authState.currentBusiness.attributes.timezone;

  useEffect(() => {
    const from = dateFromToday(-29).toLocaleDateString();
    const to = new Date().toLocaleDateString();

    setDateFilterOption(`${monthDayYear(from, timezone)} - ${monthDayYear(to, timezone)}`);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, [authState.currentBusiness]);

  const fetchData = async (pageNumber = 1, query = '', filterItems = {} as FilterItemType) => {
    await revenueList(pageNumber, query, filterItems);
  };

  const tableConfig = {
    title: Constants.Table.Title.Revenue,
    theadData: Constants.Table.Header.Revenue,
    totalCount: state.totalCount,
    pageRange: Constants.PerPageCount.Revenue,
    dateFilterDefaultOption: dateFilterOption,
    filterItems: [
      {
        title: Constants.Filter.Status.Value,
        submenu: [
          titleize(Constants.Statuses.Revenue.Pending),
          titleize(Constants.Statuses.Revenue.Scheduled),
          titleize(Constants.Statuses.Revenue.Paid)
        ]
      },
      {
        title: Constants.Filter.Date.Value,
        submenu: [
          Constants.Filter.Date.Options.Today,
          Constants.Filter.Date.Options.Last7Days,
          Constants.Filter.Date.Options.Last30Days,
          Constants.Filter.Date.Options.Custom
        ]
      }
    ],
    OnSearch: fetchData,
    onPageChange: async (page: number, query: string, filterItems = {} as FilterItemType) => {
      if (state.totalCount > 0) await fetchData(page, query, filterItems);
    }
  };

  const statusColor = (attributes: RevenueAttributeType) => {
    switch (attributes.status) {
    case Constants.Statuses.Revenue.Pending:
      return 'color-gray';
    case Constants.Statuses.Revenue.Scheduled:
      return 'color-amber';
    default:
      return 'color-primary';
    }
  };

  const prepareLineItems = async (invoiceId: number) => {
    await lineItemList(invoiceId);

    setInvoiceid(invoiceId);
    setShowLineItemsModal(true);
  };

  return (
    <Fragment>
      <RevenueLineItems
        show={showLineItemsModal}
        onClose={() => setShowLineItemsModal(false)}
        invoiceId={invoiceId as number}
      />
      <BaseHeader title={titleize(Constants.StorageKey.Revenue)} />
      <div>
        { !state.hasRevenue && !loading ? (
          <EmptyState
            type={Constants.Table.Empty.RevenueTable}
          />
        ) : (
          <Fragment>
            {state.graphItems.total_amount > 0 &&
              <RevenueChart revenueData={state.graphItems} dateFilter={dateFilterOption} empty={!state.hasRevenue} />}
            <br />
            <CTable {...tableConfig}>
              {state.items.map(({attributes}, index) => (
                <CTableRow key={index}>
                  <CTableData>{attributes.reference}</CTableData>
                  <CTableData>
                    <p className={statusColor(attributes)}>
                      {attributes.status}
                    </p>
                  </CTableData>
                  <CTableData>{attributes.amount_due}</CTableData>
                  <CTableData>{attributes.due_date}</CTableData>

                  <CTableData>
                    {<CButton
                      small
                      smallText
                      color="inverted"
                      onClick={() => prepareLineItems(attributes.id)}
                      styles={{pointerEvents: 'auto'}}
                    >
                      View line items
                    </CButton>}
                  </CTableData>
                </CTableRow>
              ))}
            </CTable>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default RevenueTable;
