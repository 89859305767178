import {ReactNode} from 'react';
import { Card } from 'react-bootstrap';
import { getActiveClasses } from '../utils/helpers';

interface Props {
  children: ReactNode,
}
const CPageCard = ({ children }: Props) => {
  const getClass = () => {
    const cssClasses = {
      'c-card__page': true
    };
    return getActiveClasses(cssClasses);
  };

  return <Card className={getClass()}>{children}</Card>;
};

CPageCard.Title = ({ children }: Props) => {
  return <section className="c-card__page--header">{children}</section>;
};
CPageCard.Content = ({ children }: Props) => {
  return <section className="c-card__page--content">{children}</section>;
};

export default CPageCard;
