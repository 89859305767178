import { Fragment, useContext, useEffect, useState } from 'react';
import CButton from '../../shared/forms/Button';
import { CTable, CTableRow, CTableData } from '../../shared/CTable';
import { Constants } from '../../utils/constants';
import { AuthContext } from '../../contexts/useAuthContext';
import { ServiceContext } from './useServiceContext';
import { useHistory } from 'react-router-dom';
import EmptyState from '../../shared/EmptyState';
import CConfirmationModal from '../../shared/forms/ConfirmationModal';
import {pluralize, titleize, truncateString} from '../../utils/stringManipulation';
import {SubCategoryType, ServiceAttributeType, ServiceStateObjectType} from '../../utils/types/service_types';
import {FilterItemType} from '../../utils/types/util_types';
import ServiceDetails from './ServiceDetails';

const ServiceTable = () => {
  const { state: authState, isAdmin } = useContext(AuthContext);
  const { categoryList, deleteService, refetchServices, serviceList, state } = useContext(ServiceContext);

  const [servicesConfig, setServicesConfig] =
    useState<ServiceStateObjectType>(state.services);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState('');
  const [categories, setCategories] = useState([]);
  const [showServiceDetailsModal, setshowServiceDetailsModal] = useState(false);
  const [confirmedBookingCount, setConfirmedBookingCount] = useState(0);
  const [data, setData] = useState<ServiceAttributeType>({} as ServiceAttributeType);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, [authState.currentBusiness]);

  useEffect(() => {
    (async () => {
      const { services = {} as ServiceStateObjectType } = state;
      const { items = [], totalCount = 0, pageNumber, refetch } = services;
      setServicesConfig({ items, totalCount, pageNumber } as ServiceStateObjectType);
      if (refetch) {
        refetchServices();
        await fetchData(pageNumber);
      }
    })();
  }, [state.services]);

  useEffect(() => {
    (async () => {
      const categoryValues = await categoryList(1, '', true);
      if (Object.keys(categoryValues).length === 0) return;

      const categoryListing = categoryValues.map(({ attributes }: SubCategoryType) => attributes.name);
      setCategories(categoryListing);
    })();
  }, []);

  const addNewService = () => {
    history.push(Constants.Links.Service.Path.CreateService);
  };

  const fetchData = async (pageNumber = 1, query = '', filterItems = {} as FilterItemType) => {
    await serviceList(pageNumber, query, filterItems);
  };

  const tableConfig = {
    title: Constants.Table.Title.Services,
    theadData: Constants.Table.Header.Services,
    totalCount: servicesConfig.totalCount,
    pageRange: Constants.PerPageCount.Services,
    filterItems: [
      {
        title: Constants.Filter.Status.Value,
        submenu: [
          titleize(Constants.Statuses.Service.Published),
          titleize(Constants.Statuses.Service.Draft)
        ]
      },
      {
        title: Constants.Filter.Category.Value,
        submenu: categories
      }
    ],
    OnSearch: fetchData,
    onPageChange: async (page: number, query: string, filterItems = {} as FilterItemType) => {
      if (servicesConfig.totalCount > 0) await fetchData(page, query, filterItems);
    }
  };

  const setDeleteAttributes = (idAttribute: number, bookingCount: number) => {
    setShowModal(true);
    setId(`${idAttribute}`);
    setConfirmedBookingCount(bookingCount);
  };

  const handleViewService = (attributes: ServiceAttributeType) => {
    setData(attributes);
    setshowServiceDetailsModal(true);
  };

  const handleEditService = (serviceId: number) => {
    history.push(`/services/${serviceId}`);
  };

  const handleDeleteService = async () => {
    setLoading(true);
    await deleteService(id);
    setLoading(false);
    setShowModal(false);
  };

  return (
    <div>
      {!state.services.hasServices && !loading ? (
        <EmptyState
          type={Constants.Table.Empty.ServicesTable}
          onClick={addNewService}
        />
      ) : (
        <Fragment>
          <CConfirmationModal
            show={showModal}
            type="service"
            customNote={
              confirmedBookingCount > 0 && `You have ${pluralize(confirmedBookingCount, 'confirmed booking')} with this service that will be canceled.`
            }
            loading={loading}
            onClick={handleDeleteService}
            onClose={() => setShowModal(false)}
          />
          <ServiceDetails
            show={showServiceDetailsModal}
            onClose={() => setshowServiceDetailsModal(false)}
            attributes={data}
          />
          <CTable {...tableConfig}>
            {servicesConfig.items.map(({ attributes }, index) => (
              <CTableRow key={index}>
                <CTableData>
                  <strong data-title={attributes.name}>
                    {truncateString(attributes.name, 17)}
                  </strong>
                </CTableData>
                <CTableData>{attributes.price}</CTableData>
                <CTableData>
                  <p className={`service__type-${attributes.status}`}>
                    {attributes.status}
                  </p>
                </CTableData>
                <CTableData>
                  <span data-title={attributes.sub_category.attributes.name}>
                    {truncateString(attributes.sub_category.attributes.name, 10)}
                  </span>
                </CTableData>
                <CTableData>{attributes.attendants.length}</CTableData>
                {isAdmin() ? (
                  <CTableData classes="actions">
                    <CButton
                      small
                      smallText
                      onClick={() => handleViewService(attributes)}
                    >
                      View
                    </CButton>
                    <CButton
                      small
                      smallText
                      color="inverted"
                      styles={{pointerEvents: 'auto'}}
                      onClick={() => handleEditService(attributes.id)}
                    >
                      Edit
                    </CButton>
                    <CButton
                      small
                      smallText
                      color="transparent"
                      onClick={() => setDeleteAttributes(attributes.id, attributes.confirmed_booking_count)}
                    >
                      Delete
                    </CButton>
                  </CTableData>
                ): <CTableData />}
              </CTableRow>
            ))}
          </CTable>
        </Fragment>
      )}
    </div>
  );
};

export default ServiceTable;
