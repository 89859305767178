import { FormEvent, useContext, useEffect, useState } from 'react';
import CConfirmationModal from '../../../shared/forms/ConfirmationModal';
import CPageCard from '../../../shared/PageCard';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CButton from '../../../shared/forms/Button';
import CImageUpload from '../../../shared/forms/ImageUpload';
import CFormInput from '../../../shared/forms/Input';
import { getFormValue, setFormValue } from '../../../utils/formHelpers';
import CFormSelect from '../../../shared/forms/Select';
import CSlideCheckbox from '../../../shared/forms/SlideCheckbox';
import { AuthContext } from '../../../contexts/useAuthContext';
import { DashboardContext } from '../../../contexts/useDashboardContext';
import { Constants } from '../../../utils/constants';
import {
  getUploadUrl,
  getRemoteImageUrl,
} from '../../../utils/3rdParties/awsS3';
import { imageFileName, pluralize } from '../../../utils/stringManipulation';
import {
  errorToast,
  isSubscriptionEnabled,
  successToast,
} from '../../../utils/helpers';
import AlertComponent from '../../../shared/AlertComponent';
import { useHistory } from 'react-router-dom';
import { StaffAttributeType } from '../../../utils/types/staff_types';
import AdditionalInfo from './AdditionalInfo';

type FormErrorKey =
  | 'first_name'
  | 'last_name'
  | 'profile.gender'
  | 'profile.contact_number'
  | 'password'
  | 'email';
type FormErrorType = Record<FormErrorKey, string[]>;

const SettingsProfile = () => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState<Partial<FormErrorType>>({});
  const [loading, setLoading] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [accountDeactivationLoading, setSubscriptionActionLoading] = useState(
    false
  );
  const [bookable, setBookable] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [showAvatarUploader, setShowAvatarUploader] = useState(false);
  const [avatarUpdated, setAvatarUpdated] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [profileUuid, setProfileUuid] = useState('');
  const [avatar, setAvatar] = useState('');
  const [bookingCount, setBookingCount] = useState(0);

  const {
    state: authState,
    getAdmin,
    getEmployee,
    isAdmin,
    editUser,
    deactivateAccount,
    reactivateAccount,
  } = useContext(AuthContext);
  const { s3Upload } = useContext(DashboardContext);

  const history = useHistory();
  const historyState = history.location.state as any;

  const genderOptions = [
    {
      label: 'Male',
      value: Constants.Gender.Male,
    },
    {
      label: 'Female',
      value: Constants.Gender.Female,
    },
  ];

  useEffect(() => {
    (async () => {
      if (
        historyState?.from === 'from-service-page' &&
        historyState?.action === 'add'
      ) {
        successToast('Your service has been saved as a draft');
        window.history.replaceState({}, document.title);
      }

      const result = isAdmin() ? await getAdmin() : await getEmployee();

      if (!result || Object.keys(result).length === 0) return;

      const profileAttributes = result.profile.attributes;

      setFormValue(setFormData, 'firstName', result.first_name);
      setFormValue(setFormData, 'lastName', result.last_name);
      setFormValue(setFormData, 'email', result.email);
      setFormValue(
        setFormData,
        'phoneNumber',
        profileAttributes.contact_number
      );
      setFormValue(setFormData, 'gender', profileAttributes.gender);
      setBookable(result.bookable);
      setShowAvatarUploader(result.set_up_complete);
      setProfileUuid(profileAttributes.uuid_token);

      const splitAvatarLink =
        profileAttributes.avatar && profileAttributes.avatar.split('?')[0];
      setAvatar(splitAvatarLink);
    })();
  }, [avatarUpdated]);

  useEffect(() => {
    const currentPassword = getFormValue(formData, 'currentPassword');

    if (
      !getFormValue(formData, 'password') ||
      !getFormValue(formData, 'newPassword') ||
      !currentPassword
    )
      return;

    setPasswordMatch(
      !!currentPassword &&
        getFormValue(formData, 'password') ===
          getFormValue(formData, 'newPassword')
    );
  }, [
    getFormValue(formData, 'currentPassword'),
    getFormValue(formData, 'password'),
    getFormValue(formData, 'newPassword'),
  ]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isPasswordRequest =
      (getFormValue(formData, 'password') || '').length > 0;

    try {
      isPasswordRequest ? setPasswordLoading(true) : setLoading(true);
      const result = await editUser({ ...formData, bookable });

      if (result) {
        if (!avatar) setShowAvatarUploader(true);
        setFormErrors({});

        const upcomingBookingCount = Number(
          result.attributes.upcoming_bookings_count
        );

        if (upcomingBookingCount > 0 && isAdmin()) {
          setShowAlert(true);
          setBookingCount(upcomingBookingCount);
        }
      }

      isPasswordRequest ? setPasswordLoading(false) : setLoading(false);

      resetPassword();
    } catch (error) {
      if (typeof error === 'string') {
        errorToast(error);
        setPasswordLoading(false);
      } else {
        setFormErrors(error as FormErrorType);
        setPasswordLoading(false);
        if (!getFormValue(formData, 'password')) setLoading(false);
      }

      resetPassword();
    }
  };

  const resetPassword = () => {
    setFormValue(setFormData, 'password', undefined);
    setFormValue(setFormData, 'currentPassword', undefined);
    setFormValue(setFormData, 'newPassword', undefined);
  };

  const uploadAvatarToApi = async (avatar: string) => {
    const result = await editUser({ avatar });

    if (result) setAvatarUpdated(!avatarUpdated);
    setAvatarLoading(false);
  };

  const uploadAvatar = async (file: File) => {
    setAvatarLoading(true);
    const presignedUrl = await getUploadUrl(profileUuid, file);
    await s3Upload(presignedUrl as string, file);

    if (presignedUrl) {
      const fileName = imageFileName(profileUuid);
      const avatarLink = getRemoteImageUrl(fileName);
      await uploadAvatarToApi(avatarLink);
    } else {
      setAvatarLoading(false);
      errorToast('An error has occurred while uploading. Try again');
    }
  };

  const deactivate = async () => {
    setSubscriptionActionLoading(true);
    try {
      await deactivateAccount();
      hideConfirmationModal();
    } catch (error) {
      errorToast(error as string);
      hideConfirmationModal();
    }
  };

  const reactivate = async () => {
    setSubscriptionActionLoading(true);
    try {
      await reactivateAccount();
      hideConfirmationModal();
    } catch (error) {
      errorToast(error as string);
      hideConfirmationModal();
    }
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
    setSubscriptionActionLoading(false);
  };

  const isReactivatable = () => {
    if (!isSubscriptionEnabled) return false;

    return authState.user.subscription?.attributes?.reactivatable;
  };

  return (
    <div>
      <CConfirmationModal
        show={showConfirmationModal}
        onClick={isReactivatable() ? reactivate : deactivate}
        loading={accountDeactivationLoading}
        type="account"
        cta={isReactivatable() ? 'Reactivate' : 'Deactivate'}
        hasStandardNote={false}
        customNote={`Are you sure you want to ${
          isReactivatable() ? 'reactivate' : 'deactivate'
        } your account?`}
        onClose={() => setShowConfirmationModal(false)}
      />

      {showAlert && (
        <AlertComponent
          showIcon={true}
          type="info"
          text={`Even though you can not be booked subsequently, you have ${pluralize(
            bookingCount,
            'existing upcoming booking'
          )} across your business(es)`}
        />
      )}

      <CPageCard>
        <CPageCard.Title>
          <h5>Your Profile Information</h5>
          <p>Edit &amp; manage your profile</p>
        </CPageCard.Title>
        <CPageCard.Content>
          <Form onSubmit={handleSubmit} className="business-profile__form pb-0">
            <Row className="mt-2">
              <Col lg={4} xl={3}>
                <Row className="business-profile__images">
                  <Col sm={6} lg={12}>
                    {showAvatarUploader ? (
                      <CImageUpload
                        loading={avatarLoading}
                        image={avatar}
                        referrer="profile"
                        onChange={uploadAvatar}
                      />
                    ) : (
                      <Card className="business-profile__empty">
                        <Card.Body>
                          <div className="home-text p-0">
                            <p className="mt-3 small">
                              Update your profile to enable you to upload your
                              avatar
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    )}
                    <div className="mt-3">
                      <AdditionalInfo />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={1} className="profile__form-vr" />
              <Col lg={7} xl={8}>
                <Row>
                  <Col sm={6}>
                    <CFormInput
                      placeholder="First Name"
                      id="first_name"
                      value={getFormValue(formData, 'firstName')}
                      errors={formErrors['first_name']}
                      onInput={value =>
                        setFormValue(setFormData, 'firstName', value)
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    <CFormInput
                      placeholder="Last Name"
                      id="last_name"
                      value={getFormValue(formData, 'lastName')}
                      errors={formErrors['last_name']}
                      onInput={value =>
                        setFormValue(setFormData, 'lastName', value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <CFormSelect
                      placeholder="Gender"
                      name="gender"
                      value={getFormValue(formData, 'gender')}
                      errors={formErrors['profile.gender']}
                      onInput={value =>
                        setFormValue(setFormData, 'gender', value)
                      }
                      options={genderOptions}
                      optionValue="value"
                      optionName="label"
                    />
                  </Col>

                  <Col sm={6}>
                    <CFormInput
                      placeholder="Phone Number"
                      id="phone_number"
                      type="tel"
                      value={getFormValue(formData, 'phoneNumber')}
                      errors={formErrors['profile.contact_number']}
                      onInput={value =>
                        setFormValue(setFormData, 'phoneNumber', value)
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <CFormInput
                      placeholder="Email Address"
                      type="email"
                      readOnly={true}
                      value={getFormValue(formData, 'email')}
                      errors={formErrors['email']}
                      onInput={value =>
                        setFormValue(setFormData, 'email', value)
                      }
                    />
                  </Col>

                  {isAdmin() ? (
                    <Col md={6} className="profile__form_slider mt-3">
                      <CSlideCheckbox
                        name="bookable"
                        value={bookable}
                        onInput={setBookable}
                      />
                      <p className="small">Let customers book you</p>
                    </Col>
                  ) : (
                    <Col sm={6}>
                      <CFormInput
                        placeholder="Job Role"
                        value={
                          (authState.user as StaffAttributeType).job_role
                            .attributes.name
                        }
                        readOnly={true}
                      />
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col sm={12} className="text-end mt-5">
                    {isSubscriptionEnabled && isAdmin() && (
                      <CButton
                        medium
                        color="transparent"
                        onClick={() => setShowConfirmationModal(true)}
                      >
                        {isReactivatable()
                          ? 'Reactivate account'
                          : 'Deactivate account'}
                      </CButton>
                    )}

                    <CButton
                      medium
                      loading={loading}
                      disabled={avatarLoading || passwordLoading}
                      type="submit"
                    >
                      Save changes
                    </CButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </CPageCard.Content>
      </CPageCard>

      <br />

      <CPageCard>
        <CPageCard.Title>
          <h5>Change Password</h5>
          <p>Change your account password</p>
        </CPageCard.Title>
        <CPageCard.Content>
          <Form onSubmit={handleSubmit} className="business-profile__form pb-0">
            <Row>
              <Col sm={4}>
                <CFormInput
                  placeholder="Current Password"
                  type="password"
                  readOnly={!showAvatarUploader}
                  value={getFormValue(formData, 'currentPassword')}
                  onInput={value =>
                    setFormValue(setFormData, 'currentPassword', value)
                  }
                />
              </Col>
              <Col sm={4}>
                <CFormInput
                  placeholder="New Password"
                  type="password"
                  readOnly={!showAvatarUploader}
                  value={getFormValue(formData, 'password')}
                  errors={formErrors['password']}
                  onInput={value =>
                    setFormValue(setFormData, 'password', value)
                  }
                />
              </Col>
              <Col sm={4}>
                <CFormInput
                  placeholder="Re-type New Password"
                  type="password"
                  readOnly={!showAvatarUploader}
                  value={getFormValue(formData, 'newPassword')}
                  onInput={value =>
                    setFormValue(setFormData, 'newPassword', value)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="text-end mt-3">
                <CButton
                  medium
                  loading={passwordLoading}
                  disabled={
                    !showAvatarUploader || avatarLoading || !passwordMatch
                  }
                  type="submit"
                >
                  Update password
                </CButton>
              </Col>
            </Row>
          </Form>
        </CPageCard.Content>
      </CPageCard>
    </div>
  );
};

export default SettingsProfile;
