import {useContext} from 'react';
import { Card } from 'react-bootstrap';
import { RevenueContext } from '../revenue/useRevenueContext';
import EmptyCard from '../../shared/EmptyCard';
import LineChart from '../../utils/charts/line';

interface Props {
  header: string,
  dateFilterOption?: string,
  title: string,
  description: string,
}

const DashboardRevenueCard = ({
  header,
  dateFilterOption = 'Last 30 days',
  title,
  description,
}: Props) => {
  const { state } = useContext(RevenueContext);

  return (
    <div className="dashboard-empty__card w-50">
      <Card className="home-card">
        {
          state.graphItems.total_amount > 0 ?
            <LineChart
              title={header}
              labels={state.graphItems.labels}
              yAxisData={state.graphItems.amounts}
              labelFormat="money"
              totalValue={`\u20A6${Number(state.graphItems.total_amount).toLocaleString()}`}
              dateFilter={dateFilterOption as string}
              showBorder={false}
              height="226px"
            /> :
            <EmptyCard
              title={title}
              header={header}
              description={description}
            />
        }
      </Card>
    </div>
  );
};

export default DashboardRevenueCard;
