import { Fragment, useContext, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/useAuthContext';
import { Constants } from '../../../utils/constants';
import PlanCard from './PlanCard';
import { pluralize } from '../../../utils/stringManipulation';
import AlertComponent from '../../../shared/AlertComponent';
import { urlParams } from '../../../utils/helpers';
import {
  PlanAttributeType,
  PlanFeatureType,
  PlanType,
  SubscriptionAttributeType
} from '../../../utils/types/subscription_types';

const SettingsPlans = ({ onboardingUser = false }: { onboardingUser: boolean }) => {
  const { state: authState, showIncomingPlanAlert, isAdmin } = useContext(AuthContext);

  useEffect(() => {
    showUpgradeMessage();
  }, []);

  const planDetails = authState.presetData.plans.map(({ attributes }: PlanType) => attributes);

  const descriptionFor = (plan: string) => {
    switch (plan) {
    case Constants.PlanName.Basic:
      return 'Corna Basic gives you everything you need to help you run your business';
    case Constants.PlanName.Advanced:
      return 'Corna Advanced takes you one step further than Corna Basic';
    default:
      return 'Corna Premium takes you one step further than Corna Advanced';
    }
  };

  const featuresFor = (planFeature: PlanFeatureType) => {
    return [
      [pluralize(planFeature.business_count, 'business', 'es'), true],
      [pluralize(planFeature.staff_count, 'staff account'), true],
      ['Manage paid customer bookings', true],
      ['Manage services', true],
      [`${planFeature.card_rate.toFixed(1)}% from booked services`, true],
      ['Assign staff to service', true],
      ['Manage unconfirmed bookings', planFeature.unconfirmed_bookings],
      ['View business analytics', planFeature.business_analytics]
    ];
  };

  const showUpgradeMessage = () => {
    if (onboardingUser) return false;

    return urlParams('action') === 'upgrade';
  };

  const disableSelectedPlanButton = (planCode: string, subscriptionAttributes?: SubscriptionAttributeType) => {
    if (subscriptionAttributes?.incoming_plan_code && planCode) {
      return planCode === subscriptionAttributes.incoming_plan_code;
    } else {
      return planCode === subscriptionAttributes?.plan_code;
    }
  };

  const isTrialSubscription = () => {
    return !onboardingUser && isAdmin() &&
    authState.user.subscription?.attributes?.status === Constants.Statuses.Subscription.Trial;
  };

  return (
    <div>
      {showUpgradeMessage() && <AlertComponent
        showIcon={true}
        type="info"
        text="Upgrade plan to perform this action"
      />}
      {isTrialSubscription() && <AlertComponent
        showIcon={true}
        type="warning"
        text={
          <span>
            You are currently on a 1-month Corna {authState.user.subscription?.attributes?.plan_name} trial period
            which will expire on <b>{authState.user.subscription?.attributes?.next_payment_date}</b>
          </span>
        }
      />}
      {!onboardingUser && showIncomingPlanAlert() && <AlertComponent
        showIcon={true}
        type="info"
        text={`You will be switched to the Corna ${authState.user.subscription?.attributes?.incoming_plan_name} plan at
              the end of your billing cycle`}
      />}
      {!onboardingUser && isAdmin() &&
        <Fragment>
          <div className="d-flex justify-content-between">
            <p className="text-dark mb-2">
              <strong>Plans and pricing</strong>
            </p>
          </div>
          <p className="mb-4">View pricing plans, update your current plan. All in one place</p>
        </Fragment>
      }
      <Row>
        {planDetails.map((plan: PlanAttributeType, index: number) => {
          return (
            <PlanCard
              key={index}
              id={plan.id}
              user={authState.user}
              name={plan.name}
              price={plan.amount.substring(1)}
              interval={plan.interval.slice(0, -2)}
              description={descriptionFor(plan.name)}
              features={featuresFor(plan.features)}
              selectedPlan={!onboardingUser && plan.name === authState.user.subscription?.attributes?.plan_name}
              selectedPlanButton={!onboardingUser && disableSelectedPlanButton(plan.code, authState.user.subscription?.attributes) }
            />
          );
        })}
      </Row>
    </div>
  );
};

export default SettingsPlans;
