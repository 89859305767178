export const Constants = {
  Access: {
    All: 'all',
    Admin: 'admin',
    Hide: 'hide',
    NotBasicAccount: 'notBasicAccount',
    Staff: 'staff'
  },
  Actions: {
    Analytics: {
      ResetUpdateState: 'ANALYTICS_RESET_UPDATE_STATE',
      State: 'ANALYTICS_STATE'
    },
    Auth: {
      CurrentBusinessChanged: 'BUSINESS_CHANGED',
      CurrentBusinessChanging: 'BUSINESS_CHANGING',
      InvitationRetrievalSuccess: 'INVITATION_RETRIEVAL_SUCCESS',
      LogoutSuccess: 'LOGOUT_SUCCESS',
      PresetSuccess: 'PRESET_SUCCESS',
      ResetUpdateState: 'AUTH_RESET_UPDATE_STATE',
      UserLoaded: 'USER_LOADED'
    },
    Booking: {
      ResetUpdateState: 'BOOKING_RESET_UPDATE_STATE',
      State: 'BOOKING_STATE'
    },
    Business: {
      ResetUpdateState: 'BUSINESS_RESET_UPDATE_STATE'
    },
    Dashboard: {
      ToggleSidebar: 'TOGGLE_SIDEBAR'
    },
    Plan: {
      ResetUpdateState: 'PLAN_RESET_UPDATE_STATE',
      State: 'PLAN_STATE'
    },
    Revenue: {
      ResetUpdateState: 'REVENUE_RESET_UPDATE_STATE',
      State: 'REVENUE_STATE'
    },
    ServiceData: {
      Category: {
        State: 'CATEGORY_STATE',
        Refetch: 'CATEGORY_REFRESH'
      },
      Service: {
        State: 'SERVICE_STATE',
        Refetch: 'SERVICE_REFRESH'
      },
      ResetUpdateState: 'SERVICE_AND_CATEGORY_RESET_UPDATE_STATE',
    },
    Staff: {
      Refetch: 'STAFF_REFRESH',
      ResetUpdateState: 'STAFF_RESET_UPDATE_STATE',
      State: 'STAFF_STATE'
    },
  },
  Analytics: {
    BookingsCanceled: 'bookings_canceled',
    BookingsToday: 'bookings_today',
    BookingsUpcoming: 'bookings_upcoming',
    CategoriesAndServices: 'categories_and_services',
    Revenue: 'revenue',
    TopServices: 'top_services',
    TopStaff: 'top_staff'
  },
  CardBrands: {
    Mastercard: 'mastercard',
    Verve: 'verve',
    Visa: 'visa'
  },
  ErrorMessages: {
    500: 'Oops! Seems there is an issue from our end, Contact us if this persists'
  },
  Filter: {
    Category: {
      Value: 'Category'
    },
    Date: {
      Value: 'Date',
      Options: {
        Today: 'Today',
        Last30Days: 'Last 30 days',
        Last7Days: 'Last 7 days',
        Custom: 'Custom dates'
      }
    },
    Role: {
      Value: 'Role'
    },
    Status: {
      Value: 'Status'
    }
  },
  Gender: {
    Male: 'male',
    Female: 'female'
  },
  Links: {
    Analytics: {
      Path: {
        Overview: '/analytics',
        CustomerDetails: '/analytics/customers'
      },
      Title: {
        Overview: 'Overview',
        CustomerDetails: 'Customer Details'
      }
    },
    Auth: {
      Path: {
        ForgotPassword: '/forgot-password',
        ForgotPasswordSuccess: '/forgot-password-success',
        Login: '/login',
        Register: '/register',
        ResetPassword: '/reset-password',
        ResetPasswordSuccess: '/reset-password-success',
        SelectPlan: '/select-plan',
        VerifyEmail: '/verify-email'
      },
      Title: {
        ForgotPassword: 'Forgot Password',
        ForgotPasswordSuccess: 'Forgot Password Success',
        Login: 'Login',
        Register: 'Register',
        ResetPassword: 'Reset Password',
        ResetPasswordSuccess: 'Reset Password Success',
        SelectPlan: 'Select Plan',
        VerifyEmail: 'Verify Email'
      }
    },
    Booking: {
      Path: {
        Canceled: '/bookings?status=canceled',
        Confirmed: '/bookings',
        Finished: '/bookings?status=finished',
        Unconfirmed: '/bookings?status=unconfirmed',
        ViewBooking: '/bookings/:id',
      },
      Title: {
        Canceled: 'Canceled',
        Confirmed: 'Paid & Confirmed',
        Finished: 'Completed',
        Unconfirmed: 'Unconfirmed',
        ViewBooking: 'View Booking'
      }
    },
    Paths: {
      NotFound: '/404'
    },
    Service: {
      Path: {
        CreateService: '/services/add',
        EditService: '/services/:id'
      },
      Title: {
        CreateService: 'Create Service',
        EditService: 'Edit Service'
      }
    },
    Settings: {
      Path: {
        BusinessProfile: '/settings/business-profile',
        Payments: '/settings/payments',
        Plans: '/settings/plans',
        Profile: '/settings/profile',
        Subscription: '/settings/subscription'
      },
      Title: {
        BusinessProfile: 'Business Profile',
        Payments: 'Manage Payment',
        Plans: 'Manage Plans',
        Profile: 'Profile',
        Staff: 'Manage Staff',
        Subscription: 'Subscription Details'
      }
    },
    Sidebar: {
      Path: {
        AddBusiness: '/settings/business-profile?action=add',
        Analytics: '/analytics',
        Bookings: '/bookings',
        CustomerDetails: '/analytics/customers',
        Home: '/',
        Revenue: '/revenue',
        Services: '/services',
        Settings: '/settings'
      },
      Title: {
        Analytics: 'Analytics',
        Bookings: 'Bookings',
        Home: 'Home',
        Revenue: 'Revenue',
        Services: 'Services',
        Settings: 'Settings'
      }
    }
  },
  PerPageCount: {
    AssignedServices: 3,
    Bookings: 3,
    Categories: 2,
    CustomerRatings: 3,
    Customers: 4,
    Dashboard: 3,
    LineItems: 3,
    Payments: 3,
    Revenue: 3,
    Services: 4,
    Staff: 4
  },
  PlanName: {
    Basic: 'Basic',
    Advanced: 'Advanced',
    Premium: 'Premium'
  },
  Services: {
    AddNewOptions: ['Service', 'Category']
  },
  Statuses: {
    Booking: {
      Canceled: 'canceled',
      Completed: 'completed',
      Confirmed: 'confirmed',
      Done: 'done',
      Rated: 'rated',
      State: {
        Canceled: 'Canceled',
        Paid: 'Paid',
        Pending: 'Pending'
      },
      Unconfirmed: 'unconfirmed'
    },
    Business: {
      Deactivated: 'deactivated',
      MarkedForDeactivation: 'marked_for_deactivation'
    },
    Revenue: {
      Paid: 'paid',
      Pending: 'pending',
      Scheduled: 'scheduled'
    },
    Service: {
      Draft: 'draft',
      Published: 'published'
    },
    Staff: {
      Action: {
        Deactivate: 'Deactivate',
        Delete: 'Delete',
        Reactivate: 'Reactivate'
      },
      Active: 'active',
      Deactivated: 'deactivated',
      Deleted: 'deleted',
      MarkedForDeactivation: 'marked_for_deactivation',
      Off: 'off',
      Pending: 'pending'
    },
    Subscription: {
      Canceled: 'canceled',
      MarkedForCancellation: 'marked_for_cancellation',
      Trial: 'trial'
    }
  },
  StorageKey: {
    Analytics: 'analytics',
    Booking: 'booking',
    BusinessProfile: 'business-profile',
    Dashboard: 'dashboard',
    Employee: 'employee',
    MainKey: 'c-data',
    Plan: 'plan',
    PresetData: 'preset-data',
    Revenue: 'revenue',
    Services: 'services',
    Session: 'session',
    SessionRefreshCount: 'session-refresh-count',
    Staff: 'staff',
    TransactionReference: 'transaction-reference',
    User: 'user'
  },
  Table: {
    Empty: {
      CategoriesTable: 'CategoriesTable',
      CustomersTable: 'CustomersTable',
      PaymentTable: 'PaymentTable',
      RevenueTable: 'RevenueTable',
      ServicesTable: 'ServicesTable',
      StaffTable: 'StaffTable'
    },
    Header: {
      Categories: ['Category Name', 'No. of services', ''],
      Customers: ['Name', 'Email', 'Phone Number', 'No. of bookings', 'Overall Rating', ''],
      CustomerStaff: ['Name', 'Overall Rating', ''],
      LineItems: ['Service', 'Attendant', 'Start Date', 'Next Payment Date', 'Card'],
      Payments: ['Plan name', 'Amount', 'Start Date', 'Next Payment Date', 'Card', ''],
      Revenue: ['Payout Ref', 'Status', 'Amount', 'Due Date', ''],
      Services: [
        'Service Name',
        'Amount',
        'Status',
        'Category',
        'No. of Attendants',
        ''
      ],
      Staff: ['Name', 'Contact Information', 'Role', 'Status', '', ''],
      TopCategories: ['Category', 'No. of Services'],
      TopServices: ['Service', 'No. of Bookings'],
      TopStaff: ['Name', 'No of Bookings']
    },
    Title: {
      Categories: 'Categories',
      Customers: 'Customers',
      LineItems: 'Line items',
      Payments: 'Payments',
      Revenue: 'Revenue history',
      Services: 'Services',
      Staff: 'Staff'
    }
  },
  UserType: {
    Admin: 'Admin',
    Customer: 'Customer',
    Employee: 'Employee'
  }
};

export const EmptyStateTexts = {
  [Constants.Table.Empty.CategoriesTable]: {
    text: 'A category is required to create a service.<br>Click the button to create one',
    buttonText: 'Add Category'
  },
  [Constants.Table.Empty.CustomersTable]: {
    text: 'No customers have booked yet'
  },
  [Constants.Table.Empty.PaymentTable]: {
    text: 'You have not made any payments yet'
  },
  [Constants.Table.Empty.RevenueTable]: {
    text: 'You have not received any payouts yet'
  },
  [Constants.Table.Empty.ServicesTable]: {
    text: 'You have no services yet',
    buttonText: 'Add Service'
  },
  [Constants.Table.Empty.StaffTable]: {
    text: 'You’re riding solo',
    buttonText: 'Add Staff'
  }
};

export const LogolessBanks = [
  'Abbey Mortgage Bank',
  'ASO Savings and Loans',
  'Bowen Microfinance Bank',
  'CEMCS Microfinance Bank',
  'Citibank Nigeria',
  'Coronation Merchant Bank',
  'Ekondo Microfinance Bank',
  'Eyowo',
  'FSDH Merchant Bank Limited',
  'Globus Bank',
  'Hackman Microfinance Bank',
  'Hasal Microfinance Bank',
  'Ibile Microfinance Bank',
  'Infinity MFB',
  'Lagos Building Investment Company Plc.',
  'Mayfair MFB',
  'One Finance',
  'PalmPay',
  'Parallex Bank',
  'Parkway - ReadyCash',
  'Paycom',
  'Petra Microfinance Bank Plc',
  'Rand Merchant Bank',
  'Sparkle Microfinance Bank',
  'Suntrust Bank',
  'TAJ Bank',
  'TCF MFB',
  'Titan Bank'
];

export const UtcTimezone = 'Etc/UTC';
