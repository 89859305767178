import { useContext } from 'react';
import { Card } from 'react-bootstrap';
import EmptyCard from '../../shared/EmptyCard';
import { CTableRow } from '../../shared/CTable';
import { BookingContext } from '../bookings/useBookingContext';
import { CDashboardTable, CDashboardTableData } from '../../shared/CDashboardTable';
import {convertDurationToHrsAndMins, convertTimeTo12HourFormat, numericDate} from '../../utils/stringManipulation';
import CButton from '../../shared/forms/Button';
import {Constants} from '../../utils/constants';
import {dateFromToday} from '../../utils/helpers';
import {BookingStateObjectType} from '../../utils/types/booking_types';
import {AuthContext} from '../../contexts/useAuthContext';

interface Props {
  header: string,
  bookableAdminAttendant: boolean,
  data: BookingStateObjectType,
  title: string,
  description: string
}

const DashboardBookingsUpcoming = ({
  header,
  bookableAdminAttendant,
  data,
  title,
  description
}: Props) => {
  const { bookingList } = useContext(BookingContext);
  const {state: authState} = useContext(AuthContext);

  const tableConfig = {
    totalCount: data.totalCount,
    pageRange: Constants.PerPageCount.Dashboard,
    onPageChange: async (page: number, query: string) => {
      if (data.totalCount > 0) await fetchData(page, query);
    }
  };

  const fetchData = async (pageNumber = 1, query = '') => {
    await bookingList(pageNumber, query);
  };

  const timezone = authState.currentBusiness.attributes.timezone;

  const day = (dateString: string) => {
    const date = new Date(dateString);

    return Intl.DateTimeFormat('en',{
      day: 'numeric',
      timeZone: timezone
    }).format(date);
  };

  const month = (dateString: string) => {
    const date = new Date(dateString);

    return Intl.DateTimeFormat('en',{
      month: 'short',
      timeZone: timezone
    }).format(date);
  };

  const upcomingBookingsLink =
    `${Constants.Links.Sidebar.Path.Bookings}?`+
    `from=${numericDate(dateFromToday(1).toLocaleDateString(), timezone)}&`+
    `to=${numericDate(dateFromToday(8).toLocaleDateString(), timezone)}`;

  return (
    <div className="dashboard-empty__card">
      <Card className="home-card">
        { data.items.length > 0 ? (
          <div className="dashboard-card__body">
            <p className="dashboard-card__title">
              <strong>{header}</strong>
              <small className="ms-2 color-gray">Next 7 days</small>
              <span className="pull-right">
                <CButton
                  color="primary"
                  smallText
                  small
                  href={upcomingBookingsLink}
                >
                  View all
                </CButton>
              </span>
            </p>
            <CDashboardTable {...tableConfig}>
              {data.items.map(({ attributes }, index) => (
                <CTableRow key={index}>
                  <CDashboardTableData>
                    <strong>{day(attributes.date)}</strong>
                    <p className="small">{month(attributes.date)}</p>
                  </CDashboardTableData>
                  <CDashboardTableData>
                    <strong>{attributes.service_name}</strong>
                    <span className="color-gray">{` for ${attributes.customer_name}`}</span>
                    <p className="small">
                      <span className="color-gray">
                        {convertTimeTo12HourFormat(attributes.date, timezone)}
                      </span>
                      <span className="color-gray">, </span>
                      <span className="color-gray">{convertDurationToHrsAndMins(attributes.service_duration)}</span>
                      {!bookableAdminAttendant && <span>
                        <span className="color-gray"> &#8226; </span>
                        <span className="color-gray">{attributes.attendant_details.name}</span>
                      </span>}
                    </p>
                  </CDashboardTableData>
                  <CDashboardTableData>
                    <strong>{attributes.service_amount}</strong>
                  </CDashboardTableData>
                </CTableRow>
              ))}
            </CDashboardTable>
          </div>
        ) : (
          <EmptyCard
            title={title}
            header={header}
            description={description}
          />
        )}
      </Card>
    </div>
  );
};

export default DashboardBookingsUpcoming;
