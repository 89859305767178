import { useState, useContext, useEffect, FormEvent } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { AuthContext } from '../contexts/useAuthContext';
import CFormInput from '../shared/forms/Input';
import CCard from '../shared/CCard';
import CButton from '../shared/forms/Button';
import {validateEmail} from '../utils/formHelpers';
import AlertComponent from '../shared/AlertComponent';
import { Constants } from '../utils/constants';
import {logo, urlParams} from '../utils/helpers';
import useAnalytics from '../hooks/useAnalytics';

const Login = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(true);
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [timeOutAlert, setTimeOutAlert] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [formError, setFormError] = useState<string>('');
  const { adminLogin, employeeLogin, getPresetData } = useContext(AuthContext);
  const { trackUser } = useAnalytics();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      await getPresetData();
    })();
  }, []);

  useEffect(() => {
    const action = urlParams('action');
    if (action) setTimeOutAlert(action === 'time-out');
  }, []);

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = !isAdmin
        ? await employeeLogin({ email, password })
        : await adminLogin({ email, password });

      setLoading(false);
      if (result) {
        localStorage.setItem(Constants.StorageKey.SessionRefreshCount, String(0));

        await getPresetData();

        const data = {
          userId: result.user.id,
          userType: result.user.is_admin ? 'Admin' : 'Employee',
          userEmail: result.user.email
        };

        trackUser(data);
        history.push(Constants.Links.Sidebar.Path.Home);
      }
    } catch (error) {
      setLoading(false);
      if (typeof error === 'string' ) {
        setFormError(error);
      }
    }
  };

  const changeLoginRole = () => {
    setIsAdmin(prev => !prev);
    setFirstLoad(false);
  };

  const getAnimation = () => {
    if (!isFirstLoad) {
      return isAdmin ? 'anim-zoom-invert' : 'anim-zoom';
    }
    return '';
  };

  return (
    <div className="c-auth__gradient">
      {timeOutAlert && <AlertComponent type="warning" text="Your session has expired. Log back in" />}
      <CCard
        content={
          <div className="c-auth__content">
            <img
              src={logo}
              alt="Logo"
              className={`app-logo ${getAnimation()}`}
            />
            <br />
            <br />
            <h1>Welcome back.</h1>
            <p className="mt-2">
              Sign in using your {isAdmin ? 'admin' : 'employee'} credentials
            </p>
            <br />
            <Form onSubmit={handleLogin}>
              {formError && (
                <AlertComponent
                  text={formError}
                  type="danger"
                  onClose={() => setFormError('')}
                />
              )}
              <div className="mb-3">
                <CFormInput
                  placeholder="Email"
                  type="email"
                  id="email"
                  value={email}
                  onInput={setEmail}
                />
              </div>

              <div className="mb-3">
                <CFormInput
                  placeholder="Password"
                  type="password"
                  id="password"
                  value={password}
                  onInput={setPassword}
                />
              </div>

              <Row className="align-items-center">
                <Col sm="6" className="c-auth__forgot">
                  <a
                    href={Constants.Links.Auth.Path.ForgotPassword}
                    className="link-text"
                  >
                    Forgot Password?
                  </a>
                </Col>
                <Col sm="6">
                  <CButton
                    color="primary"
                    type="submit"
                    loading={loading}
                    disabled={!validateEmail(email) || !password}
                  >
                    Sign In
                  </CButton>
                </Col>
              </Row>
            </Form>
          </div>
        }
      />
      <div>
        <p className="mt-3 form-meta">
          Don't have an account?&nbsp;
          <a href={Constants.Links.Auth.Path.Register} className="link-text">
            Sign up
          </a>
        </p>
      </div>
      <div>
        <p className="mt-2">
          <button onClick={() => changeLoginRole()} className="link-text">
            Log in as {!isAdmin ? 'Admin' : 'Employee'} instead
          </button>
        </p>
      </div>
    </div>
  );
};

export default withRouter(Login);
