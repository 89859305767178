import { useState } from 'react';
import CModal from '../../shared/forms/Modal';
import CButton from '../../shared/forms/Button';
import {ServiceAttributeType} from '../../utils/types/service_types';
import {convertDurationToHrsAndMins} from '../../utils/stringManipulation';

interface Props {
  attributes: ServiceAttributeType;
  onClose: () => void;
  show: boolean;
}

const ServiceDetails = ({ attributes, onClose, show }: Props) => {
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setLoading(true);
    onClose();
    setLoading(false);
  };

  const attendantNames = () => {
    if (!attributes.attendants) return;

    const names = attributes.attendants.map((attendant) => attendant.label);
    return names.join(', ');
  };

  return (
    <CModal
      show={show}
      onClose={closeModal}
      title={`${attributes.name} — ${attributes.status}`}
      footer={
        <CButton
          loading={loading}
          onClick={closeModal}
          small
        >
          Ok
        </CButton>
      }
    >
      <div>
        <p className="mt-3">{attributes.description}</p>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="pe-lg-3 pe-4">
            <h6 className="text-dark">Category</h6>
            <p className="small">{attributes?.sub_category?.attributes?.name}</p>
          </div>
          <div className="pe-lg-3 pe-4">
            <h6 className="text-dark">Price</h6>
            <p className="small">{attributes.price}</p>
          </div>
          <div className="pe-lg-3 pe-4">
            <h6 className="text-dark">Duration</h6>
            <p className="small">{convertDurationToHrsAndMins(attributes.duration)}</p>
          </div>
        </div>
        <hr />
        <h6 className="text-dark">{`Attendants - ${attributes.attendants?.length}`}</h6>
        <small>{attendantNames()}</small>
      </div>
    </CModal>
  );
};

export default ServiceDetails;
