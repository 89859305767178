import {FormEvent, useContext, useEffect, useRef, useState} from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CButton from '../../../shared/forms/Button';
import CImageUpload from '../../../shared/forms/ImageUpload';
import CFormInput from '../../../shared/forms/Input';
import CFormSelect from '../../../shared/forms/Select';
import CFormTextarea from '../../../shared/forms/Textarea';
import CPageCard from '../../../shared/PageCard';
import AddIcon from '../../../assets/images/dashboard/add-icon.svg';
import CConfirmationModal from '../../../shared/forms/ConfirmationModal';
import { getFormValue, setFormValue } from '../../../utils/formHelpers';
import { Constants } from '../../../utils/constants';
import { AuthContext } from '../../../contexts/useAuthContext';
import { DashboardContext } from '../../../contexts/useDashboardContext';
import { BusinessContext } from './useBusinessContext';
import { handleScriptLoad, loadScript } from '../../../utils/3rdParties/googleAutocomplete';
import { deleteImage, getUploadUrl, getRemoteImageUrl } from '../../../utils/3rdParties/awsS3';
import { errorToast, urlParams } from '../../../utils/helpers';
import { imageFileName } from '../../../utils/stringManipulation';
import {useHistory} from 'react-router-dom';
import {CountryType, StateType} from '../../../utils/types/util_types';
import {BusinessFormAttributeType, BusinessType} from '../../../utils/types/business_types';
import {CategoryType} from '../../../utils/types/service_types';
import OnboardingTaskList from '../../../shared/OnboardingTaskList';

type AddressValueType = string |  undefined;
type AddressResultType = {
  addressId?: number
  address1?: AddressValueType,
  address2?: AddressValueType,
  city?: AddressValueType,
  stateCode?: AddressValueType,
  postalCode?: AddressValueType,
  latitude?: AddressValueType,
  longitude?: AddressValueType,
  timezone?: number,
  countryCode?: AddressValueType
}

type FormErrorKey =
  'name' |
  'category_id' |
  'contact_email' |
  'contact_number' |
  'description' |
  'size' |
  'starting_time' |
  'closing_time' |
  'address.address1' |
  'address.address2' |
  'address.city' |
  'address.postal_code' |
  'address.state_code' |
  'address.country_code';
type FormErrorType = Record<FormErrorKey, string[]>

const SettingsBusinessProfile = () => {
  const [formData, setFormData] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageIndex, setImageIndex] = useState(-1);
  const [images, setImages] = useState<string[]>([]);
  const [imageDeleteList, setImageDeleteList] = useState<string[]>([]);
  const [states, setStates] = useState<StateType[]>([]);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [imageCount, setImageCount] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState<number>();
  const [addressResult, setAddressResult] = useState<Partial<AddressResultType>>({});
  const [addressId, setAddressId] = useState<number>();
  const [address1, setAddress1] = useState<AddressValueType>('');
  const [address2, setAddress2] = useState<AddressValueType>('');
  const [city, setCity] = useState<AddressValueType>('');
  const [postalCode, setPostalCode] = useState<AddressValueType>('');
  const [latitude, setLatitude] = useState<AddressValueType>('');
  const [longitude, setLongitude] = useState<AddressValueType>('');
  const [timezone, setTimezone] = useState<number | null>();
  const [stateCode, setStateCode] = useState<AddressValueType>('');
  const [countryCode, setCountryCode] = useState<AddressValueType>('');
  const [formErrors, setFormErrors] = useState<Partial<FormErrorType>>({});
  const autoCompleteRef = useRef(null);
  const [businessDetails, setBusinessDetails] = useState<BusinessType>({} as BusinessType);

  const { state: authState, getAdmin, setCurrentBusiness } = useContext(AuthContext);
  const { addBusiness, editBusiness } = useContext(BusinessContext);
  const { s3Upload } = useContext(DashboardContext);

  const history = useHistory();

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(autoCompleteRef, setAddressResult)
    );
  }, []);

  useEffect(() => {
    if (Object.keys(addressResult).length === 0) return;

    setAddress1(addressResult.address1);
    setAddress2(addressResult.address2 || address2);
    setCity(addressResult.city || city);
    setPostalCode(addressResult.postalCode || postalCode);
    setLatitude(addressResult.latitude);
    setLongitude(addressResult.longitude);
    setTimezone(addressResult.timezone);
    setStateCode(addressResult.stateCode);
    setCountryCode(addressResult.countryCode);
  }, [addressResult]);

  useEffect(() => {
    (async () => {
      if (createAction) return;

      const business = authState.currentBusiness;
      if (Object.keys(business).length === 0) return;

      setBusinessDetails(business);
      populateForm(business);
    })();
  }, [authState.currentBusiness]);

  useEffect(() => {
    if(!presetData) return;

    const values = Object.values(presetData.countries) as CountryType[];
    const countryList = values.map(({code, name}) => ({
      code: code,
      name: name
    }));

    setCountries(countryList);
  }, []);

  useEffect(() => {
    if(!presetData) return;

    const values = Object.values(presetData.countries) as CountryType[];
    const stateList = values.flatMap(({states}) => states as StateType) as StateType[];

    setStates(stateList);
  }, [countryCode]);

  const imageMaxCount = 5;
  const createAction = urlParams('action') === 'add';
  const presetData = JSON.parse(localStorage.getItem(Constants.StorageKey.PresetData) as string);
  const categories = presetData?.categories?.map(({ attributes }: CategoryType) => attributes);
  const setUpComplete = businessDetails?.attributes?.set_up_complete && !createAction;

  const addNewImage = () => {
    if (images.length < imageMaxCount) {
      setImages(prev => [...prev, '']);
      setTimeout(() => {
        const objDiv = document.querySelector('.business-profile__images');
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      }, 100);
      setImageCount(imageCount + 1);
    }
  };

  const populateForm = (business: BusinessType) => {
    if (!business) return;

    const businessAttributes = business.attributes;

    setFormValue(setFormData, 'businessName', businessAttributes.name);
    setFormValue(setFormData, 'businessType', businessAttributes.category.attributes.id);
    setFormValue(setFormData, 'businessDescription', businessAttributes.description);
    setFormValue(setFormData, 'businessEmail', businessAttributes.contact_email);
    setFormValue(setFormData, 'businessPhoneNumber', businessAttributes.contact_number);
    setFormValue(setFormData, 'businessSize', businessAttributes.size);
    setFormValue(setFormData, 'startTime', openHoursInTimezone(businessAttributes.starting_time));
    setFormValue(setFormData, 'closingTime', openHoursInTimezone(businessAttributes.closing_time));

    const images = businessAttributes.images.map((image) => image.split('?')[0]);
    setImages(images);
    setImageCount(businessAttributes.images.length);

    setAddressId(businessAttributes.address_id);
    setAddress1(businessAttributes.address1);
    setAddress2(businessAttributes.address2);
    setCity(businessAttributes.city);
    setPostalCode(businessAttributes.postal_code);
    setLatitude(businessAttributes.latitude);
    setLongitude(businessAttributes.longitude);
    setStateCode(businessAttributes.state_code);
    setCountryCode(businessAttributes.country_code);
  };

  const openHoursInUTC = (date?: string) => {
    if (!date) return;

    const hourAndMin = date.split(':');
    const dateValue = new Date();
    dateValue.setHours(Number(hourAndMin[0]), Number(hourAndMin[1]), 0);
    return dateValue.toUTCString();
  };

  const openHoursInTimezone = (dateString?: string) => {
    if (!dateString) return;

    const date = new Date(dateString);

    const timeInTimezone = date.toLocaleTimeString([], { hour12: false });
    const splitTime = timeInTimezone.split(':');
    splitTime.pop();

    return splitTime.join(':');
  };

  const formDetails = {
    name: getFormValue(formData, 'businessName'),
    category: getFormValue(formData, 'businessType'),
    description: getFormValue(formData, 'businessDescription'),
    email: getFormValue(formData, 'businessEmail'),
    phoneNumber: getFormValue(formData, 'businessPhoneNumber'),
    size: getFormValue(formData, 'businessSize'),
    startTime: openHoursInUTC(getFormValue(formData, 'startTime')),
    closingTime: openHoursInUTC(getFormValue(formData, 'closingTime')),
    addressId,
    address1,
    address2,
    postalCode,
    city,
    latitude,
    longitude,
    timezone,
    stateCode,
    countryCode
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    setFormErrors({});

    try {
      if (createAction) {
        const result = await addBusiness(formDetails);

        if (result) {
          const businesses = [...authState.user.businesses, result];
          setCurrentBusiness(businesses, result);
          history.push(Constants.Links.Settings.Path.BusinessProfile);
        }
      } else {
        const result = await editBusiness(formDetails);

        if (result) {
          const admin = await getAdmin();
          setCurrentBusiness(admin.businesses, result);
        }
      }

      imageDeleteList.map((image) => deleteImage(image));

      setImages((val)=>val.filter(i=>i));
      setImageCount(images.filter(item => item).length);
    } catch (error) {
      setFormErrors(error as FormErrorType);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (action: string, index: number, file: File) => {
    setImageIndex(index);

    if (!businessDetails) return;

    if (action === 'add') {
      return handleNewImage(index, file);
    } else if (action === 'change') {
      return handleExistingImage(index, file);
    }
  };

  const uploadImagesToApi = async (imageList: BusinessFormAttributeType) => {
    await editBusiness(imageList, 'images');
  };

  const handleNewImage = async (index: number, file: File) => {
    let imagePosition;
    const newImage = URL.createObjectURL(file);
    images.pop();
    setImages([...images, newImage]);

    const lastImage = images[images.length - 1];
    if (lastImage) {
      const lastImagePosition = lastImage.split('?')[0].split('/').pop()?.slice(-1);
      imagePosition = Number(lastImagePosition) + 1;
    } else {
      imagePosition = index + 1;
    }

    const presignedUrl = await getUploadUrl(businessDetails, file, imagePosition);
    await s3Upload(presignedUrl as string, file);

    if (presignedUrl) {
      const fileName = imageFileName(businessDetails, imagePosition);
      const image = getRemoteImageUrl(fileName);
      const imageList = [...images, image];
      setImages(imageList);
      await uploadImagesToApi(imageList as BusinessFormAttributeType);
    } else {
      errorToast('An error has occurred while uploading. Try again');
    }

    setImageIndex(-1);
  };

  const handleExistingImage = async (index: number, file: File) => {
    const item = URL.createObjectURL(file);
    images.splice(index, 1, item);
    setImages(images);

    const presignedUrl = await getUploadUrl(businessDetails, file, index + 1);
    await s3Upload(presignedUrl as string, file);

    if (presignedUrl) {
      const fileName = imageFileName(businessDetails, index + 1);
      const image = getRemoteImageUrl(fileName);
      images.splice(index, 1, image);
      setImages(images);
      await uploadImagesToApi(images as BusinessFormAttributeType);
    } else {
      errorToast('An error has occurred while uploading. Try again');
    }

    setImageIndex(-1);
  };

  const handleRemoveImage = async () => {
    if (activeImageIndex === undefined) return;

    const removedImage = images[activeImageIndex];

    const otherImages = images.filter((el, i) => i !== activeImageIndex);

    const fileName = removedImage.split('?')[0].split('/').pop();

    setImageDeleteList([...imageDeleteList, fileName] as string[]);

    setImages(otherImages);
    await uploadImagesToApi(otherImages as BusinessFormAttributeType);
    setImageCount(prev => prev - 1);
    setShowConfirmationModal(false);
  };

  const setActiveImage = (index: number) => {
    setActiveImageIndex(index);
    setShowConfirmationModal(true);
  };

  return (
    <div className="business-profile">
      <OnboardingTaskList />

      <CConfirmationModal
        show={showConfirmationModal}
        type="image"
        onClick={handleRemoveImage}
        cta="Remove"
        onClose={() => setShowConfirmationModal(false)}
      />
      <CPageCard>
        <CPageCard.Title>
          <h5>Business Information</h5>
          <p>Edit &amp; manage your business information</p>
        </CPageCard.Title>
        <CPageCard.Content>
          <Form onSubmit={handleSubmit} className="business-profile__form">
            <Row className="mt-2">
              <Col lg={4} xl={3}>
                <div className="form-title__wrapper">
                  <h5 className="form-title">{`Business Images (${imageCount}/${imageMaxCount})`}</h5>
                  {images.length > 0 && images.length < 5 && (
                    <CButton icon small color="regular" onClick={addNewImage}>
                      <img src={AddIcon} alt="Add more images" />
                    </CButton>
                  )}
                </div>

                <p className="small mb-2">
                  Your first image will be the display image your customers see
                </p>
                <Row className="business-profile__images">
                  {images.map((image, index) => {
                    return (
                      <Col sm={6} lg={12} key={index}>
                        <CImageUpload
                          loading={imageIndex === index}
                          image={image}
                          onChange={file => handleImageChange(image.length > 0 ? 'change' : 'add', index, file)}
                          onRemove={() => setActiveImage(index)}
                        />
                      </Col>
                    );
                  })}
                </Row>
                {images.length === 0 && (
                  <Card className="business-profile__empty">
                    <Card.Body>
                      <div className="home-text">
                        <CButton
                          icon
                          small
                          name="new image"
                          color="regular"
                          onClick={addNewImage}
                          disabled={!setUpComplete}
                        >
                          <img src={AddIcon} alt="Add more images" />
                        </CButton>
                        {setUpComplete ?
                          <div>
                            <p>Add a new image</p>
                            <p className="mt-3 small">
                              If you don't have at least one image, a default one will be set for you
                            </p>
                          </div> :
                          <p className="mt-3 small">
                            Update your business details in order to add images
                          </p>
                        }
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </Col>
              <Col lg={1} className="business-profile__hr px-3" />
              <Col lg={7} xl={8}>
                <Row>
                  <Col sm={6}>
                    <CFormInput
                      placeholder="Business Name"
                      value={getFormValue(formData, 'businessName')}
                      id="name"
                      errors={formErrors['name']}
                      onInput={value => setFormValue(setFormData, 'businessName', value)}
                    />
                  </Col>
                  <Col sm={6}>
                    <CFormSelect
                      placeholder="Business Type"
                      name="businessCategory"
                      value={getFormValue(formData, 'businessType')}
                      errors={formErrors['category_id']}
                      onInput={value => setFormValue(setFormData, 'businessType', value)}
                      options={categories}
                      optionValue="id"
                      optionName="name"
                    />
                  </Col>
                  <Col sm={12}>
                    <CFormTextarea
                      placeholder="Business description"
                      value={getFormValue(formData, 'businessDescription')}
                      onInput={value => setFormValue(setFormData, 'businessDescription', value)}
                    />
                  </Col>

                  <Col sm={6}>
                    <CFormInput
                      placeholder="Business Email"
                      type="email"
                      value={getFormValue(formData, 'businessEmail')}
                      errors={formErrors['contact_email']}
                      onInput={value => setFormValue(setFormData, 'businessEmail', value)}
                    />
                  </Col>

                  <Col sm={6}>
                    <CFormInput
                      placeholder="Business Phone Number"
                      type="tel"
                      value={getFormValue(formData, 'businessPhoneNumber')}
                      id="contact_number"
                      errors={formErrors['contact_number']}
                      onInput={value => setFormValue(setFormData, 'businessPhoneNumber', value)}
                    />
                  </Col>

                  <Col sm={12}>
                    <h5 className="form-title mt-3">Size &amp; Opening Hours</h5>
                  </Col>

                  <Col md={4}>
                    <CFormSelect
                      placeholder="Business Size"
                      name="businessSize"
                      value={getFormValue(formData, 'businessSize')}
                      errors={formErrors['size']}
                      onInput={value => setFormValue(setFormData, 'businessSize', value)}
                      options={presetData?.business_sizes}
                    />
                  </Col>

                  <Col md={4}>
                    <CFormInput
                      placeholder="Start time"
                      type="time"
                      value={getFormValue(formData, 'startTime')}
                      errors={formErrors['starting_time']}
                      id="starting_time"
                      onInput={value => setFormValue(setFormData, 'startTime', value)}
                    />
                  </Col>

                  <Col md={4}>
                    <CFormInput
                      placeholder="Closing time"
                      type="time"
                      value={getFormValue(formData, 'closingTime')}
                      errors={formErrors['closing_time']}
                      id="closing_time"
                      onInput={value => setFormValue(setFormData, 'closingTime', value)}
                    />
                  </Col>

                  <Col sm={12}>
                    <h5 className="form-title mt-3">Business Address</h5>
                  </Col>

                  <Col sm={6}>
                    <CFormInput
                      placeholder="Address"
                      innerRef={autoCompleteRef}
                      value={address1}
                      errors={formErrors['address.address1']}
                      onInput={setAddress1}
                    />
                  </Col>
                  <Col sm={6}>
                    <CFormInput
                      placeholder="Apt no, Unit no"
                      value={address2}
                      errors={formErrors['address.address2']}
                      onInput={setAddress2}
                    />
                  </Col>
                  <Col sm={6}>
                    <CFormInput
                      placeholder="City"
                      value={city}
                      errors={formErrors['address.city']}
                      onInput={setCity}
                    />
                  </Col>
                  <Col sm={6}>
                    <CFormInput
                      placeholder="Postal code"
                      type="number"
                      value={postalCode}
                      errors={formErrors['address.postal_code']}
                      onInput={setPostalCode}
                    />
                  </Col>
                  <Col sm={6}>
                    <CFormSelect
                      placeholder="State"
                      name="State code"
                      value={stateCode}
                      errors={formErrors['address.state_code']}
                      onInput={setStateCode}
                      options={states}
                      optionValue="code"
                      optionName="name"
                    />
                  </Col>
                  <Col sm={6}>
                    <CFormSelect
                      placeholder="Country"
                      name="country"
                      value={countryCode}
                      errors={formErrors['address.country_code']}
                      onInput={setCountryCode}
                      options={countries}
                      optionValue="code"
                      optionName="name"
                    />
                  </Col>
                  <Col sm={12} className="text-end mt-3">
                    <CButton medium loading={loading} disabled={imageIndex !== -1} type="submit">
                      Save Changes
                    </CButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </CPageCard.Content>
      </CPageCard>
    </div>
  );
};

export default SettingsBusinessProfile;
