import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../contexts/useAuthContext';
import BaseHeader from '../shared/Header';
import Sidebar from '../shared/Sidebar';
import { settingsPages } from '../utils/routes';
import Loader from '../shared/Loader';

const withSettingsLayout = (WrappedComponent: any) => ({ ...props }) => {
  const { state, filterLinksWithAccess, isAdmin } = useContext(AuthContext);

  return (
    <div className="c-dashboard">
      <Sidebar />
      {state.switchingBusiness ? <Loader /> :
        <section className="c-dashboard__layout">
          <div className="c-dashboard__container">
            <BaseHeader title="Settings" />
            <nav className="settings-tab__nav">
              <ul className="settings-tab__list">
                {filterLinksWithAccess(settingsPages).map(({ title, path }) => {
                  return (
                    <li key={title} className="settings-tab__item">
                      <NavLink exact to={path} className="settings-tab__link">
                        {title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div className={`settings-tab__content ${!isAdmin() ? 'mt-4' : ''}`}>
              <WrappedComponent {...props} />
            </div>
          </div>
        </section>
      }
    </div>
  );
};

export default withSettingsLayout;
