import { FC, useCallback, useContext } from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import { DashboardProvider } from '../contexts/useDashboardContext';
import { AuthContext } from '../contexts/useAuthContext';
import { Constants } from '../utils/constants';
import { isSubscriptionEnabled } from '../utils/helpers';
import SelectPlan from '../auth/SelectPlan';

interface IProps extends RouteProps {
  component: any;
}

export const PrivateRoute: FC<IProps> = ({ component: Component, ...rest }) => {
  const { state: authState } = useContext(AuthContext);

  const dashboardProvider = useCallback((props: RouteProps) => {
    if (!authState.user.subscription && isSubscriptionEnabled) return <SelectPlan showAlert={true} />;

    return <DashboardProvider routerProps={props}>
      <Component {...props} />
    </DashboardProvider>;
  },[authState.user]);

  const setRoute = (props: RouteProps) => {
    if (authState.user) {
      if (!isSubscriptionEnabled) return dashboardProvider(props);

      if (authState.user.subscription) {
        return dashboardProvider(props);
      } else {
        return <SelectPlan showAlert={true} />;
      }
    } else {
      return <Redirect to={Constants.Links.Auth.Path.Login} />;
    }
  };

  return (
    <Route
      {...rest}
      render={props => setRoute(props)}
    />
  );
};
