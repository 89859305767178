import AssignedServices from './assigned-services';

const AdditionalInfo = () => {
  return (
    <div>
      <AssignedServices />
    </div>
  );
};

export default AdditionalInfo;
