import {createContext, useReducer, useContext, useEffect, PropsWithChildren} from 'react';
import {Constants, UtcTimezone} from '../../utils/constants';
import {getLineItemList, getRevenueList} from '../../utils/apiCalls/revenue';
import { AuthContext } from '../../contexts/useAuthContext';
import { initializeState, updateContextState, handleError, dateFromToday } from '../../utils/helpers';
import {numericDate} from '../../utils/stringManipulation';
import {ApiErrorType, FilterItemType} from '../../utils/types/util_types';
import {
  RevenueActionType,
  RevenueGraphDataType,
  RevenueResetUpdateActionType,
  RevenueStateObjectType
} from '../../utils/types/revenue_types';

const initialState = {
  hasRevenue: false,
  updateState: false,
  items: [],
  graphItems: {} as RevenueGraphDataType,
  totalCount: 0,
  pageNumber: 1,
  lineItems: {
    items: [],
    totalCount: 0,
    pageNumber: 1,
  }
};

const RevenueReducer = (
  state: RevenueStateObjectType,
  action: RevenueActionType | RevenueResetUpdateActionType
) => {
  switch (action.type) {
  case Constants.Actions.Revenue.State:
    return {
      ...state,
      ...action.payload as RevenueStateObjectType
    };
  case Constants.Actions.Revenue.ResetUpdateState:
    return {
      ...state,
      updateState: action.payload as boolean
    };
  default:
    return { ...state };
  }
};

const actions = (dispatch: (_: any) => void, state: RevenueStateObjectType) => {
  const { state: authState } = useContext(AuthContext);

  const resetUpdateState = (payload: boolean = true) => {
    dispatch({
      type: Constants.Actions.Revenue.ResetUpdateState,
      payload
    });
  };

  return {
    revenueList: async (
      pageNumber: number = 1,
      query: string = '',
      filterItems = {} as FilterItemType
    ) => {
      const { user, currentBusinessId } = authState;
      const today = dateFromToday();
      const monthBefore = dateFromToday(-29);
      const fromDate = filterItems?.date?.from || numericDate(monthBefore.toLocaleDateString(), UtcTimezone);
      const toDate = filterItems?.date?.to || numericDate(today.toLocaleDateString(), UtcTimezone);
      const status = filterItems?.status?.toLowerCase();

      try {
        resetUpdateState();
        const revenue = await getRevenueList(
          user,
          currentBusinessId,
          pageNumber,
          query,
          status,
          fromDate,
          toDate
        );

        const { data } = revenue;

        const payload = {
          hasRevenue: data.has_items,
          items: data.data.reverse(),
          graphItems: data.graph_data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page
        };

        dispatch({
          type: Constants.Actions.Revenue.State,
          payload
        });
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    lineItemList: async (invoiceId: number, pageNumber: number = 1) => {
      const { user } = authState;

      try {
        resetUpdateState();
        const line_items = await getLineItemList(
          user,
          invoiceId,
          pageNumber
        );

        const { data } = line_items;

        const lineItemPayload = {
          items: data.data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page
        };

        const payload = {...state, lineItems: lineItemPayload};

        dispatch({
          type: Constants.Actions.Revenue.State,
          payload
        });
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    }
  };
};

type RevenueContextActions = ReturnType<typeof actions>
type RevenueContextType = {state: RevenueStateObjectType} & RevenueContextActions
export const RevenueContext = createContext<RevenueContextType>({} as RevenueContextType);

export default function RevenueProvider({ children, value }: PropsWithChildren<{value?: RevenueStateObjectType}>) {
  const [state, dispatch] = useReducer(
    RevenueReducer,
    initializeState(Constants.StorageKey.Revenue, { ...initialState, ...(value ?? {})})
  );

  useEffect(() => {
    if (state.updateState)
      updateContextState(Constants.StorageKey.Revenue, state);
  }, [state]);

  return (
    <RevenueContext.Provider value={{ state, ...actions(dispatch, state) }}>
      {children}
    </RevenueContext.Provider>
  );
}
