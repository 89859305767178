import {ChangeEvent, useEffect, useState} from 'react';

const CInlineSearch = ({ value, onInput }: { value: string, onInput: (_: any) => void }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const { value } = e.target;
    setInputValue(value);
    onInput(value);
  };
  return (
    <div className="c-search__box">
      <span className="c-search__icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.75 6.66667C2.75 4.50355 4.50355 2.75 6.66667 2.75C8.82978 2.75 10.5833 4.50355 10.5833 6.66667C10.5833 8.82978 8.82978 10.5833 6.66667 10.5833C4.50355 10.5833 2.75 8.82978 2.75 6.66667ZM6.66667 1.25C3.67512 1.25 1.25 3.67512 1.25 6.66667C1.25 9.65821 3.67512 12.0833 6.66667 12.0833C7.89199 12.0833 9.02228 11.6765 9.92982 10.9905L13.4697 14.5303C13.7626 14.8232 14.2374 14.8232 14.5303 14.5303C14.8232 14.2374 14.8232 13.7626 14.5303 13.4697L10.9905 9.92982C11.6765 9.02228 12.0833 7.89199 12.0833 6.66667C12.0833 3.67512 9.65821 1.25 6.66667 1.25Z"
            fill="#737A91"
            fillOpacity="0.5"
          />
        </svg>
      </span>
      <input
        className="c-search"
        placeholder="Search"
        type="search"
        value={inputValue}
        onChange={handleInput}
      />
    </div>
  );
};

export default CInlineSearch;
