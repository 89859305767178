import {ReactNode} from 'react';
import CPagination from './CPagination';
import {OnSearchType} from '../utils/types/util_types';

const CDashboardTableHead = ({ theadData }: { theadData: string[] }) => {
  return (
    <thead className="dashboard-card__title">
      <tr>
        {theadData.map((name, index) => (
          <th className="c-dashboard-table__header" key={index}>
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

interface CTopServiceRowProps {
  children: ReactNode;
  className: string
}
const CTopServiceRow = ({ children, className }: CTopServiceRowProps) => {
  return <tr className={`${className} top-service__row`}>{children}</tr>;
};

interface CDashboardTableDataProps {
  children?: ReactNode;
  styles?: object;
  classes?: string;
}
const CDashboardTableData = ({ styles, classes = '', children }: CDashboardTableDataProps) => {
  return (
    <td className={`c-dashboard-table__data ${classes}`} style={{ ...styles }}>
      {children}
    </td>
  );
};

interface CDashboardTableProps {
  children: ReactNode;
  theadData?: string[];
  title?: string;
  dateFilterOption?: string;
  totalCount?: number;
  onPageChange?: OnSearchType;
  pageRange?: number;
  wrapperClassName?: string;
}
const CDashboardTable = ({
  children,
  theadData,
  title,
  dateFilterOption,
  totalCount = 0,
  onPageChange,
  pageRange = 5,
  wrapperClassName = ''
}: CDashboardTableProps) => {
  return (
    <div className="c-table__dashboard-box">
      <div>
        {title && <section className="dashboard-card__title">
          <span className="text-dark">
            <h4 className="color-primary">{title}</h4>
            {dateFilterOption && <small className="ms-2 color-gray">{dateFilterOption}</small>}
          </span>
        </section>}
        <div className={`c-table__wrapper ${wrapperClassName}`}>
          <table className="c-table__dashboard">
            {theadData && <CDashboardTableHead theadData={theadData} />}
            <tbody>
              {totalCount > 0 && children}
            </tbody>
          </table>
        </div>
      </div>
      {totalCount > pageRange && <CPagination
        totalCount={totalCount as number}
        pageRange={pageRange as number}
        onChange={onPageChange as OnSearchType}
        dashboard={true}
      />}
    </div>
  );
};

export { CDashboardTable, CDashboardTableData, CTopServiceRow, CDashboardTableHead };
