import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { chartLabelFormatter } from '../stringManipulation';

const DoughnutChart = ({ dataset, labelFormat }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const labels = () => {
    return Object.keys(dataset);
  };

  const values = () => {
    return Object.values(dataset);
  };

  const data = {
    labels: labels(),
    datasets: [
      {
        label: 'Percentage of customers',
        data: values(),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          pointStyle: 'circle',
          usePointStyle: true
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const l = context.label;
            const formattedValue = chartLabelFormatter(context.formattedValue, labelFormat);

            return `${l}: ${formattedValue}`;
          }
        }
      },
    }
  };

  const hasData = () => {
    return Object.keys(dataset).length > 0;
  };

  return (
    <div>
      {hasData() ?
        <Doughnut
          data={data}
          options={options}
          type="doughnut"
        /> :
        <div className="text-center">
          No data available
        </div>
      }
    </div>
  );
};

export default DoughnutChart;
