import {createContext, PropsWithChildren, useContext, useEffect, useReducer} from 'react';
import {Constants, UtcTimezone} from '../../../utils/constants';
import {AuthContext} from '../../../contexts/useAuthContext';
import {
  createBusiness,
  findBusiness,
  getDashboardAnalytics,
  updateBusiness,
  updateImages
} from '../../../utils/apiCalls/business';
import {dateFromToday, handleError, initializeState, successToast, updateContextState} from '../../../utils/helpers';
import {numericDate} from '../../../utils/stringManipulation';
import {ApiErrorType, FilterItemType} from '../../../utils/types/util_types';
import {
  BusinessFormAttributeType,
  BusinessResetUpdateActionType,
  BusinessStateObjectType,
  BusinessStateType,
} from '../../../utils/types/business_types';

const initialState = {
  updateState: false
};

const BusinessReducer = (state: BusinessStateObjectType, action: BusinessResetUpdateActionType) => {
  switch (action.type) {
  case Constants.Actions.Business.ResetUpdateState:
    return {
      ...state,
      updateState: action.payload
    };
  default:
    return state;
  }
};

const actions = (dispatch: (_: any) => void) => {
  const { state: authState } = useContext(AuthContext);

  const resetUpdateState = (payload: any = true) => {
    dispatch({
      type: Constants.Actions.Business.ResetUpdateState,
      payload
    });
  };

  return {
    addBusiness: async (businessInfo: BusinessFormAttributeType) => {
      const {user} = authState;

      try {
        resetUpdateState();
        const business = await createBusiness(user, businessInfo);

        const {data} = business;

        successToast(data.message);

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    editBusiness: async (businessInfo: BusinessFormAttributeType, updateAction: string = 'all') => {
      const {user, currentBusinessId} = authState;
      try {
        resetUpdateState();
        if (updateAction === 'all') {
          const business = await updateBusiness(
            user,
            currentBusinessId,
            businessInfo
          );
          const {data} = business;

          successToast(data.message);

          return data.data;
        }
        await updateImages(user, currentBusinessId, businessInfo as string[]);
        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    getBusiness: async () => {
      const {user, currentBusinessId} = authState;

      try {
        resetUpdateState();
        const business = await findBusiness(user, currentBusinessId);

        const {data} = business;

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    getAnalyticsData: async (
      partial = true,
      filterItems = {} as FilterItemType
    ) => {
      const {user, currentBusinessId} = authState;

      const weekBefore = dateFromToday(-7);
      const fromDate = filterItems?.date?.from || numericDate(weekBefore.toLocaleDateString(), UtcTimezone);
      const toDate = filterItems?.date?.to || numericDate(dateFromToday().toLocaleDateString(), UtcTimezone);

      try {
        resetUpdateState();

        const analytics = await getDashboardAnalytics(user, currentBusinessId, partial, fromDate, toDate);

        const {data} = analytics;

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    }
  };
};

type BusinessContextActions = ReturnType<typeof actions>
type BusinessContextType = BusinessStateType & BusinessContextActions
export const BusinessContext = createContext<BusinessContextType>({} as BusinessContextType);

export default function BusinessProvider({ children, value }: PropsWithChildren<{value?: BusinessStateObjectType}>) {
  const [state, dispatch] = useReducer(
    BusinessReducer,
    initializeState(
      Constants.StorageKey.BusinessProfile,
      { ...initialState, ...(value as BusinessStateObjectType ?? {})})
  );

  useEffect(() => {
    if (state.updateState)
      updateContextState(Constants.StorageKey.BusinessProfile, state);
  }, [state]);

  return (
    <BusinessContext.Provider value={{ state, ...actions(dispatch) }}>
      {children}
    </BusinessContext.Provider>
  );
}
